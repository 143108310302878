import React, { useEffect } from 'react';
import nprogress from 'nprogress';
import { SW } from '@types';
import Layer, { StaticLayer } from './Layer';
import PretendNotification from './PretendNotification';
import FlashNotifications from './FlashNotifications';
import Pixels from './Pixels';
import HeadMeta from './HeadMeta';
import PopupRoutes from '../../../PopupRoutes';
import { useAppMetadata } from '../../../context/AppMetadata';
import HelpDeskButton from './HelpDeskButton';
import HelpDeskPortal from './HelpDeskPortal';
import Dialog from '../../partials/Dialog';

interface IBaseLayoutProps {
  headMetaConfig?: SW.Models.IHeadMeta;
}

const BaseLayout: React.FC<IBaseLayoutProps> = ({
  children,
  headMetaConfig,
}) => {
  const appMetadata = useAppMetadata();

  useEffect(() => {
    if (appMetadata.isChunksFetching) {
      nprogress.start();
    } else {
      nprogress.done();
    }
    return nprogress.done;
  }, [appMetadata.isChunksFetching]);

  return (
    <>
      <Layer>
        <HeadMeta config={headMetaConfig} />
        <PretendNotification />
        {children}
        <Pixels />
      </Layer>
      <PopupRoutes />
      <Dialog />
      <StaticLayer>
        <FlashNotifications />
        <HelpDeskButton />
        <HelpDeskPortal />
      </StaticLayer>
    </>
  );
};

export default BaseLayout;
