import React from 'react';

import { PopupLink } from '../../../Router';
import Button from '../../../Button';

import styles from './SignButtons.pcss';

interface ISignButtonsProps {
  hasSignUpButton: boolean;
}

const SignButtons: React.FC<ISignButtonsProps> = ({ hasSignUpButton }) => (
  <div>
    {hasSignUpButton && (
      <Button
        as={PopupLink}
        name="sign-up"
        className={styles.button}
        theme="darkblue"
        size="xl"
      >
        Sign Up. It{`'`}s Free!
      </Button>
    )}
    <Button
      as={PopupLink}
      name="sign-in"
      className={styles.button}
      theme="outlined"
      size="xl"
    >
      Sign In
    </Button>
  </div>
);

SignButtons.defaultProps = {
  hasSignUpButton: true,
};

export default SignButtons;
