import React from 'react';

import SearchFormContainer from '../../../partials/SearchForm';
import DesktopMediaQuery from '../../../partials/media_queries/DesktopMediaQuery';
import MobileMediaQuery from '../../../partials/media_queries/MobileMediaQuery';

import styles from './HeroArea.pcss';

const HeroArea: React.FC = () => (
  <div className={styles.container}>
    <div className={styles.searchFormContainer}>
      <div className={styles.headerContainer}>
        <h1 className={styles.headerContent}>What kind of job</h1>
      </div>

      <div className={styles.subHeaderContainer}>
        <h2 className={styles.subHeaderContent}>
          <div className={styles.subHeaderText}>
            <DesktopMediaQuery>are you looking for today?</DesktopMediaQuery>
            <MobileMediaQuery>are you looking for?</MobileMediaQuery>
          </div>
        </h2>
      </div>

      <SearchFormContainer prefix="landing" landing />
    </div>
  </div>
);

export default HeroArea;
