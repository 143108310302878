import React from 'react';
import set from 'lodash/set';
import keys from 'lodash/keys';
import toPairs from 'lodash/toPairs';
import {
  reduxForm,
  SubmissionError,
  InjectedFormProps,
  updateSyncErrors,
  getFormSyncErrors,
  getFormValues,
  startSubmit,
  stopSubmit,
  setSubmitSucceeded,
  setSubmitFailed,
} from 'redux-form';

import { useSelector, useDispatch } from '../../../../store';
import FormContext from '../FormContext';

const raiseSubmissionError = ({ errors, _error }) => {
  throw new SubmissionError(
    toPairs(errors).reduce((acc, [path, val]) => set(acc, path, val), {
      _error,
    })
  );
};

interface ICustomFormProps {
  onSubmit(data: object): Promise<object>;
  autoSubmit: boolean;
  resetOnSubmit: boolean;
}

type InternalFormProps = ICustomFormProps &
  InjectedFormProps<{}, ICustomFormProps>;

const InternalForm: React.FC<InternalFormProps> = (props) => {
  const dispatch = useDispatch();
  const syncErrors = useSelector(getFormSyncErrors(props.form));
  const formValues = useSelector(getFormValues(props.form));

  const enhancedSubmit = (formData) => {
    return props
      .onSubmit(formData)
      .then((data) => {
        if (props.resetOnSubmit) {
          props.untouch(...Object.keys(formData));
          props.reset();
        }
        return data;
      })
      .catch(raiseSubmissionError);
  };

  if (
    props.autoSubmit &&
    props.initialized &&
    !props.anyTouched &&
    props.pristine &&
    props.valid
  ) {
    props.handleSubmit((formData) => enhancedSubmit(formData))();
  }

  const submitForm = (formData) => {
    dispatch(startSubmit(props.form));
    return enhancedSubmit(formData)
      .then((submitResult) => {
        dispatch(stopSubmit(props.form));
        dispatch(setSubmitSucceeded(props.form));
        return submitResult;
      })
      .catch((submitError) => {
        const error = submitError.errors;
        dispatch(stopSubmit(props.form, error));
        dispatch(setSubmitFailed(props.form, ...keys(error)));
        return error;
      });
  };

  const handleSubmitEvent = (e, customs = { values: {}, validate: true }) => {
    const { values = {}, validate = true } = customs;
    if (validate) {
      return props.handleSubmit((formData) =>
        enhancedSubmit({ ...formData, ...values })
      )(e);
    }
    return submitForm({ ...formValues, ...values });
  };

  return (
    <FormContext.Provider
      value={{
        id: props.form,
        isSubmitting: props.submitting,
        isDirty: props.dirty,
        error: props.error,
        dispatchSubmit: handleSubmitEvent,
        reset: props.reset,
        change: props.change,
        touch: props.touch,
        untouch: props.untouch,
        setSyncError: (path, syncError) => {
          dispatch(
            updateSyncErrors(
              props.form,
              set(syncErrors, path, syncError),
              undefined
            )
          );
        },
      }}
    >
      {props.children}
    </FormContext.Provider>
  );
};

export default reduxForm<object, ICustomFormProps>({})(InternalForm);
