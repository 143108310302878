import React, { useState, useRef, useCallback, useMemo } from 'react';

import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import ReactSwipe from 'react-swipe';

import CenteredContent from '../../../partials/CenteredContent';
import DesktopMediaQuery from '../../../partials/media_queries/DesktopMediaQuery';
import MobileMediaQuery from '../../../partials/media_queries/MobileMediaQuery';

import stories from '../../../../../caches/people_stories.json';
import PeopleStoryChooser from './PeopleStoryChooser';

import styles from './PeopleStoriesArea.pcss';

const PeopleStoriesArea: React.FC = () => {
  const [index, setIndex] = useState<number>(0);
  const swipeEl = useRef<ReactSwipe>(null);

  const swipePrev = useCallback((): void => swipeEl.current.prev(), [swipeEl]);
  const swipeNext = useCallback((): void => swipeEl.current.next(), [swipeEl]);
  const swipeSet = useCallback(
    (newIndex: number): void => swipeEl.current.slide(newIndex, null),
    [swipeEl]
  );
  const syncIndex = useCallback(
    (newIndex: number): void => setIndex(newIndex),
    [setIndex]
  );
  const swipeOptions = useMemo(
    () => ({ startSlide: index, callback: syncIndex }),
    [syncIndex]
  );

  return (
    <div className={styles.container}>
      <DesktopMediaQuery>
        <div
          className={styles.prevButton}
          onClick={swipePrev}
          onKeyDown={swipePrev}
          role="presentation"
        >
          <FaAngleLeft />
        </div>
      </DesktopMediaQuery>
      <h4 className={styles.header}>What people are saying about us</h4>
      <ReactSwipe
        ref={swipeEl}
        className={styles.swipeContainer}
        swipeOptions={swipeOptions}
      >
        {stories.map((story) => (
          <h4 className={styles.text} key={story}>
            <CenteredContent>{story}</CenteredContent>
          </h4>
        ))}
      </ReactSwipe>
      <MobileMediaQuery>
        <PeopleStoryChooser
          index={index}
          size={stories.length}
          setIndex={swipeSet}
        />
      </MobileMediaQuery>
      <DesktopMediaQuery>
        <div
          className={styles.nextButton}
          onClick={swipeNext}
          onKeyDown={swipeNext}
          role="presentation"
        >
          <FaAngleRight />
        </div>
      </DesktopMediaQuery>
    </div>
  );
};

export default PeopleStoriesArea;
