import type { SW } from '@types';
import type { IHttpClient } from 'context/withHttpClient';

import has from 'lodash/has';
import get from 'lodash/get';
import set from 'lodash/set';
import { authorizeUserAction } from '../session/actions';
import {
  preHandleSuccessfulRegistration,
  handleSuccessfulRegistration,
} from '../../../../utils/actions/handleSuccessfullRegistrationLocation';

const ALREADY_REGISTRED = 'This email is already registered.';

const getEnhancedAlreadyRegisteredError = (email) => [
  { key: '1', type: 'text', value: 'You already have an account. Click ' },
  {
    key: '2',
    type: 'PopupLink',
    props: {
      name: 'sign-in',
      closePopup: 'sign-up',
      title: 'here',
      value: email,
    },
  },
  { key: '3', type: 'text', value: ' to sign In. Click ' },
  {
    key: '4',
    type: 'PopupLink',
    props: {
      name: 'password-reset-request',
      closePopup: 'sign-up',
      title: 'here',
      value: email,
    },
  },
  { key: '5', type: 'text', value: ' to reset your password.' },
];

const enhanceSignupErrors = (response, customer) => {
  if (has(response, 'errors.base[0]')) {
    set(response, 'errors._error', get(response, 'errors.base[0]'));
  } else if (get(response, 'errors.email', []).includes(ALREADY_REGISTRED)) {
    set(
      response,
      'errors._error',
      getEnhancedAlreadyRegisteredError(customer.email)
    );
  }
  return response;
};

interface ISignupActionAction {
  (
    customer,
    httpClient: IHttpClient,
    changeLocation,
    startOnBoarding
  ): SW.Redux.ThunkAction<Promise<void>>;
}

const signupAction: ISignupActionAction = (
  customer,
  httpClient,
  changeLocation,
  startOnBoarding
) => (dispatch) =>
  httpClient.post('customer', { customer }).then(async (response) => {
    if (response.success) {
      await preHandleSuccessfulRegistration(changeLocation, false);
      dispatch(authorizeUserAction(response.current_user, httpClient));
      handleSuccessfulRegistration(startOnBoarding);
      return Promise.resolve(response);
    }
    return Promise.reject(enhanceSignupErrors(response, customer));
  });

export default signupAction;
