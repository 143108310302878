import Autosuggest from 'react-autosuggest';
import React, { useState } from 'react';
import escapeRegExp from 'lodash/escapeRegExp';
import cn from 'classnames';
import { SW } from '@types';

import styles from './SuggestInput.pcss';

import Suggestion from './Suggestion';

export enum SIZE_ENUM {
  XXL = 'xxl',
  XL = 'xl',
  L = 'l',
  M = 'm',
  S = 's',
  XS = 'xs',
}

const INITIAL_SUGGESTIONS = [];
const INITIAL_VALUE = '';
const MINIMUM_SYMBOLS_TO_FETCH = 2;

interface ITheme {
  container?: string;
  containerOpen?: string;
  input?: string;
  inputFocused?: string;
  inputOpen?: string;
  sectionContainer?: string;
  sectionContainerFirst?: string;
  sectionTitle?: string;
  suggestion?: string;
  suggestionFirst?: string;
  suggestionHighlighted?: string;
  suggestionsContainer?: string;
  suggestionsContainerOpen?: string;
  suggestionsList?: string;
}

interface ISuggestInputProps extends SW.Form.Input.IProps<string> {
  getSuggestionValue?(...rest: any): any;
  icon?(...rest: any): any;
  isDisabled?: boolean;
  onKeyDown?(...rest: any): any;
  onSelect?(...rest: any): any;
  placeholder?: string;
  renderSuggestion?(...rest: any): any;
  size?: SIZE_ENUM;
  theme?: ITheme;
  suggestionsProvider(...rest: any): any;
}

const SuggestInput: React.FC<ISuggestInputProps> = (props) => {
  const SuggestionComponent = props.renderSuggestion;

  const [suggestions, setSuggestions] = useState(INITIAL_SUGGESTIONS);
  const [value, setValue] = useState(INITIAL_VALUE);

  const handleChange = (event, { newValue }) => {
    setValue(newValue);
    props.onChange(newValue);
  };

  const handleKeyDown = (event) => {
    const { value: inputValue } = event.target;

    if (event.key === 'Enter') {
      setValue(inputValue);
    }

    props.onKeyDown(event);
  };

  const handleSuggestionSelected = (event, { suggestion }) => {
    event.preventDefault();
    props.onSelect(suggestion);
  };

  const handleSuggestionsClearRequest = () => {
    setSuggestions(INITIAL_SUGGESTIONS);
  };

  const handleSuggestionsFetchRequest = (data) => {
    if (data.reason === 'input-focused') return;

    if (data.value.length < MINIMUM_SYMBOLS_TO_FETCH) {
      handleSuggestionsClearRequest();
    } else {
      props
        .suggestionsProvider(data.value)
        .then((newSuggestions) => {
          setSuggestions(newSuggestions || INITIAL_SUGGESTIONS);
        })
        .catch(handleSuggestionsClearRequest);
    }
  };

  const renderSuggestion = (suggestion, { isHighlighted, query }) => {
    const suggestionValue = props.getSuggestionValue(suggestion);
    const highlightedValue = suggestionValue.replace(
      new RegExp(`(${escapeRegExp(query)})`, 'i'),
      '<strong>$1</strong>'
    );

    return (
      <SuggestionComponent
        highlightedValue={highlightedValue}
        isHighlighted={isHighlighted}
        suggestion={suggestion}
      />
    );
  };

  const inputProps = {
    disabled: props.isDisabled,
    id: props.id || props.name,
    name: props.name,
    onBlur: props.onBlur,
    onChange: handleChange,
    onFocus: props.onFocus,
    onKeyDown: handleKeyDown,
    placeholder: props.placeholder,
    type: 'search',
    value: typeof props.value === 'string' ? props.value : value,
  };

  const theme = {
    ...props.theme,
    container: cn(styles.container, props.theme.container),
    input: cn(
      styles.input,
      props.theme.input,
      styles[`input_size_${props.size}`],
      {
        [styles[`input_icon_${props.size}`]]: props.icon,
        [styles.input_error]: props.isInvalid,
      }
    ),
    suggestionsContainer: cn(
      styles.suggestionsContainer,
      props.theme.suggestionsContainer
    ),
    suggestionsContainerOpen: cn(
      styles.suggestionsContainerOpen,
      props.theme.suggestionsContainerOpen
    ),
    suggestionsList: cn(props.theme.suggestionsList, styles.suggestionsList),
  };

  const Icon = props.icon;

  return (
    <div className={cn(styles.autosuggest, props.className)}>
      {props.icon && (
        <div className={cn(styles.icon, styles[`icon_size_${props.size}`])}>
          <Icon />
        </div>
      )}
      <Autosuggest
        getSuggestionValue={props.getSuggestionValue}
        // id (required when multiple Autosuggest components are rendered on a page)
        id={props.id || props.name}
        inputProps={inputProps}
        onSuggestionSelected={handleSuggestionSelected}
        onSuggestionsClearRequested={handleSuggestionsClearRequest}
        onSuggestionsFetchRequested={handleSuggestionsFetchRequest}
        renderSuggestion={renderSuggestion}
        suggestions={suggestions}
        theme={theme}
      />
    </div>
  );
};

SuggestInput.defaultProps = {
  className: undefined,
  getSuggestionValue: (value) => value,
  icon: null,
  id: undefined,
  isDisabled: false,
  isInvalid: false,
  name: undefined,
  onBlur: () => {},
  onChange: () => {},
  onFocus: () => {},
  onKeyDown: () => {},
  onSelect: () => {},
  placeholder: undefined,
  renderSuggestion: Suggestion,
  size: SIZE_ENUM.M,
  theme: {},
  value: undefined,
};

export default SuggestInput;
