import React from 'react';
import classNames from 'classnames/bind';

import s from './CenteredContent.pcss';

const cx = classNames.bind(s);

interface ICenteredContentProps {
  className?: string;
  width?: number;
}

const CenteredContent: React.FC<ICenteredContentProps> = ({
  width,
  children,
  className,
}) => (
  <div
    className={cx(s.content, className)}
    style={width ? { width: `${width}%` } : {}}
  >
    {children}
  </div>
);

export default CenteredContent;
