import { useSelector, useDispatch } from '../../../store';
import useEffectIf from '../useEffectIf';
import { useAppMetadata } from '../../../context/AppMetadata';
import { setScriptStatus } from './actions';
import { ACTION_KEY, STATUS } from './constants';

interface IOptions {
  attrs?: Record<string, string>;
  /**
   * @default true
   */
  async?: boolean;
  /**
   * @default "text/javascript"
   */
  type?: string;
}

function useScript(src: string, options: IOptions = {}): [boolean, STATUS] {
  const dispatch = useDispatch();
  const { renderedBy } = useAppMetadata();
  const status = useSelector((state) => state[ACTION_KEY][src]);

  useEffectIf(
    () => {
      let script = document.querySelector<HTMLScriptElement>(
        `script[src="${src}"]`
      );
      const readyState = script?.dataset?.readyState;

      if (readyState) {
        if (readyState === STATUS.PENDING) {
          script.onload = () => {
            dispatch(setScriptStatus(src, STATUS.LOADED));
          };
          script.onerror = () => {
            dispatch(setScriptStatus(src, STATUS.FAILED));
          };
        }
        dispatch(setScriptStatus(src, readyState as STATUS));
      } else {
        script = document.createElement('script');
        script.src = src;
        script.async = 'async' in options ? !!options.async : true;
        script.type = options.type || 'text/javascript';

        if (options.attrs) {
          Object.entries(options.attrs).forEach(([key, val]) => {
            script.setAttribute(key, val);
          });
        }
        script.onload = () => {
          dispatch(setScriptStatus(src, STATUS.LOADED));
        };
        script.onerror = () => {
          dispatch(setScriptStatus(src, STATUS.FAILED));
        };
        document.head.appendChild(script);
        dispatch(setScriptStatus(src, STATUS.PENDING));
      }
    },
    [renderedBy === 'browser', !status],
    [src]
  );

  return [status === STATUS.LOADED, status || STATUS.PENDING];
}

export default useScript;

export { STATUS };
