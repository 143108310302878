import {
  FieldWrapperContext,
  wrapWithFieldWrapper,
  wrapWithFormControl,
  FormControlsConfigType,
} from '../../wrappers';

import AutosuggestInput, { SIZE_ENUM } from './AutosuggestInput';

const config: FormControlsConfigType<typeof AutosuggestInput> = {
  [FieldWrapperContext.layout.DEFAULT]: wrapWithFormControl(AutosuggestInput),
};

export const AutosuggestControl = wrapWithFieldWrapper(config);

export default AutosuggestInput;
export { SIZE_ENUM };
