import { createContext, useContext } from 'react';

const throwNotImplementedException = () => {
  throw Error('NotImplemented');
};

interface ISubmitCustoms {
  /**
   * @abstract
   * @member {object} ISubmitCustoms#values
   * @default {}
   */
  values?: object;
  /**
   * @abstract
   * @member {boolean} ISubmitCustoms#validate
   * @default true
   */
  validate?: boolean;
}

interface IFormContext {
  id: string;
  isSubmitting: boolean;
  isDirty: boolean;
  error?: string;
  dispatchSubmit(
    e?: React.SyntheticEvent,
    customs?: ISubmitCustoms
  ): Promise<any>;
  reset(): void;
  change(field: string, value: any): void;
  touch(...field: string[]): void;
  untouch(...field: string[]): void;
  setSyncError(field: string, error: string): void;
}

const defaultFormContext: IFormContext = {
  id: 'DefaultFormId',
  isSubmitting: false,
  isDirty: false,
  error: null,
  dispatchSubmit: throwNotImplementedException,
  reset: throwNotImplementedException,
  change: throwNotImplementedException,
  touch: throwNotImplementedException,
  untouch: throwNotImplementedException,
  setSyncError: throwNotImplementedException,
};

const FormContext = createContext<IFormContext>(defaultFormContext);

const useFormContext = () => useContext(FormContext);

export default FormContext;

export { useFormContext };
