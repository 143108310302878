import {
  FieldWrapperContext,
  wrapWithFieldWrapper,
  wrapWithFormControl,
  wrapWithReduxFormField,
  ReduxFormFieldsConfigType,
} from '../../wrappers';

import AutosuggestJobTitleField from './AutosuggestJobTitleField';
import AutosuggestJobTitleInput from '../../inputs/AutosuggestJobTitle';

const config: ReduxFormFieldsConfigType<typeof AutosuggestJobTitleInput> = {
  [FieldWrapperContext.layout.DEFAULT]: wrapWithReduxFormField(
    AutosuggestJobTitleInput,
    AutosuggestJobTitleField,
    wrapWithFormControl
  ),
};

export const AutosuggestJobTitleControl = wrapWithFieldWrapper(config);
