import type { SW } from '@types';
import type { IHttpClient } from 'context/withHttpClient';

import type { ICcpaOptions } from './ICcpaOptions';
import * as TYPES from './constants';

export const setCcpaOptionsAction = (ccpa_options: ICcpaOptions) => ({
  type: TYPES.SET,
  payload: ccpa_options,
});

interface IFetchCcpaOptionsAction {
  (httpClient: IHttpClient): SW.Redux.ThunkAction<Promise<void>>;
}

export const fetchCcpaOptionsAction: IFetchCcpaOptionsAction = (httpClient) => (
  dispatch
) => {
  return httpClient.get('ccpa_options').then((response) => {
    if (response.success) {
      dispatch(setCcpaOptionsAction(response.ccpa_options));
    }
  });
};
