import React, { useEffect, useState } from 'react';

import InfoGraphicStat from './InfoGraphicStat';
import { useHttpClient } from '../../../../../context/withHttpClient';

import { getStats } from './actions';
import initialState from '../../../../../../caches/stats.json';

const InfoGraphic: React.FC = () => {
  const httpClient = useHttpClient();
  const [stats, setStats] = useState(initialState);

  useEffect(() => {
    getStats(httpClient)
      .then(setStats)
      .catch(() => {});
  }, []);

  return (
    <div style={{ textAlign: 'center' }}>
      {Object.keys(stats).map((statName) => (
        <InfoGraphicStat
          key={statName}
          name={statName}
          stat={stats[statName]}
        />
      ))}
    </div>
  );
};

export default InfoGraphic;
