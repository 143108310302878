import React from 'react';
import cn from 'classnames';
import { FaFacebookF, FaLinkedinIn } from 'react-icons/fa';

import styles from './Social.pcss';

const data = [
  {
    icon: FaLinkedinIn,
    url: 'https://www.linkedin.com/company/startwire',
  },
  {
    icon: FaFacebookF,
    url: 'https://www.facebook.com/StartWire',
  },
];

interface ISocialProps {
  className: string;
}

const Social: React.FC<ISocialProps> = (props) => (
  <ul className={cn(styles.list, props.className)}>
    {data.map((item) => (
      <li key={item.url} className={styles.item}>
        <a
          className={styles.link}
          href={item.url}
          rel="noopener noreferrer nofollow"
          target="_blank"
        >
          <item.icon className={styles.icon} />
        </a>
      </li>
    ))}
  </ul>
);

Social.defaultProps = {
  className: null,
};

export default Social;
