import React from 'react';
import cn from 'classnames';

import styles from './Suggestion.pcss';

import SafeContent from '../../../../SafeContent';

interface ISuggestionProps {
  highlightedValue: string;
  isHighlighted: boolean;
}

const Suggestion: React.FC<ISuggestionProps> = (props) => (
  <SafeContent
    className={cn(styles.suggestion, {
      [styles.suggestion_highlighted]: props.isHighlighted,
    })}
  >
    {props.highlightedValue}
  </SafeContent>
);

export default Suggestion;
