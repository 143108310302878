import merge from 'lodash/merge';
import compact from 'lodash/compact';
import union from 'lodash/union';
import url from 'url';
import { stringify } from 'qs';
import { SW } from '@types';
import defaultHeadMetaConfig from './defaultHeadMetaConfig';

const toKeywords = (string = '') => string.split(',').map((key) => key.trim());

const getFullKeywords = (customPageMetaConfig: SW.Models.IHeadMeta) => {
  const customKeywords = toKeywords(customPageMetaConfig?.meta?.keywords);
  const defaultKeywords = toKeywords(defaultHeadMetaConfig.meta?.keywords);

  return compact(union(customKeywords, defaultKeywords)).join(', ');
};

const buildHeadMetaConfig = (
  customPageMetaConfig: SW.Models.IHeadMeta,
  location
) => {
  const pageMetaConfig = merge(
    {
      meta: {},
      link: {},
      script: {},
    },
    defaultHeadMetaConfig,
    customPageMetaConfig || {}
  );

  pageMetaConfig.meta.keywords = getFullKeywords(customPageMetaConfig);

  if (!pageMetaConfig.title.includes('StartWire')) {
    pageMetaConfig.title = `${pageMetaConfig.title} | StartWire`;
  }

  if (!pageMetaConfig.link.canonical) {
    pageMetaConfig.link.canonical = `${process.env.HOST}${location.pathname}?source=seo`;
  } else {
    const uri = url.parse(pageMetaConfig.link.canonical, true);
    if (!uri.query.source) {
      uri.query.source = 'seo';
      uri.search = `?${stringify(uri.query)}`;
      pageMetaConfig.link.canonical = url.format(uri);
    }
  }
  return pageMetaConfig;
};

export default buildHeadMetaConfig;
