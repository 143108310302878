import React from 'react';

const Placeholder: React.FC = () => {
  return (
    <div
      style={{
        display: 'block',
        width: '100%',
      }}
    />
  );
};

export default Placeholder;
