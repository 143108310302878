import React, { useState } from 'react';
import useScript from '../../../../../common/hooks/useScript';
import useEffectIf from '../../../../../common/hooks/useEffectIf';
import {
  AD_SLOT,
  MOBILE_SIDEBAR_ANIMATION_TIME,
  GOOGLE_ADS_SDK,
} from '../constants';
import InFeedAd, { InFeedPlaceholder } from './InFeedAd';
import BannerAd, { BannerPlaceholder } from './BannerAd';

import type { AdFormat } from '../types';

type AdProps = {
  slot?: AD_SLOT;
} & (
  | {
      type?: 'BANNER';
      format?: AdFormat;
    }
  | {
      type: 'IN_FEED';
    }
);

const Ad: React.FC<AdProps> = (props) => {
  const [isReady, setReady] = useState(false);
  const [isSdkLoaded] = useScript(GOOGLE_ADS_SDK, {
    attrs: {
      crossorigin: 'anonymous',
    },
  });

  useEffectIf(() => {
    const timeout = setTimeout(() => {
      setReady(true);
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }, MOBILE_SIDEBAR_ANIMATION_TIME);

    return () => {
      clearTimeout(timeout);
    };
  }, [isSdkLoaded]);

  if (!isReady) {
    switch (props.type) {
      case 'IN_FEED':
        return <InFeedPlaceholder />;
      case 'BANNER':
      default:
        return <BannerPlaceholder format={props.format} />;
    }
  }

  switch (props.type) {
    case 'IN_FEED':
      return <InFeedAd slot={props.slot} />;
    case 'BANNER':
    default:
      return <BannerAd format={props.format} slot={props.slot} />;
  }
};

Ad.defaultProps = {
  type: 'BANNER',
  format: 'W300H250',
  slot: null,
};

export default Ad;
