import React, { useState, useEffect, useCallback } from 'react';
import ReactSelect from 'react-select';
import get from 'lodash/get';
import find from 'lodash/find';
import { SW } from '@types';
import { defaultInputProps } from '../defaultInputProps';

import styles from './SelectInput.pcss';

export enum SIZE_ENUM {
  XXL = 'xxl',
  XL = 'xl',
  L = 'l',
  M = 'm',
  S = 's',
  XS = 'xs',
}

const CONTROL_HEIGHT = {
  [SIZE_ENUM.XXL]: '58px',
  [SIZE_ENUM.XL]: '48px',
  [SIZE_ENUM.L]: '40px',
  [SIZE_ENUM.M]: '34px',
  [SIZE_ENUM.S]: '28px',
  [SIZE_ENUM.XS]: '22px',
};

const INPUT_FONT_SIZE = {
  [SIZE_ENUM.XXL]: '0.9rem',
  [SIZE_ENUM.XL]: '0.9rem',
  [SIZE_ENUM.L]: '0.9rem',
  [SIZE_ENUM.M]: '0.9rem',
  [SIZE_ENUM.S]: '0.7rem',
  [SIZE_ENUM.XS]: '0.7rem',
};

const VALUE_CONTAINER_PADDING = {
  [SIZE_ENUM.XXL]: '1.2rem',
  [SIZE_ENUM.XL]: '1rem',
  [SIZE_ENUM.L]: '0.8rem',
  [SIZE_ENUM.M]: '0.7rem',
  [SIZE_ENUM.S]: '0.6rem',
  [SIZE_ENUM.XS]: '0.5rem',
};

interface ISelectInputProps extends SW.Form.Input.IProps<string | number> {
  isDisabled?: boolean;
  placeholder?: string;
  menuPlacement?: 'auto' | 'bottom' | 'top';
  size?: SIZE_ENUM;
  options?: ({ label: string; value: string | number } & any)[];
}

const SelectInput: React.FC<ISelectInputProps> = (props) => {
  const { value } = props;
  const [selected, setSelected] = useState<{
    label: string;
    value: typeof value;
  }>(undefined);

  const selectRef = React.createRef<ReactSelect>();
  const submitRef = React.createRef<HTMLButtonElement>();

  useEffect(() => {
    const selectedOption = find(props.options, { value: props.value }) || null;
    const selectedValue = get(selectedOption, 'value') || null;
    setSelected(selectedOption);
    if (selectedValue !== props.value) {
      props.onChange(selectedValue);
    }
  }, [props.value]);

  const handleChange = useCallback(
    ({ value: inputValue }) => {
      props.onChange(inputValue);
    },
    [props.onChange]
  );

  const handleKeyDown = (event) => {
    const isEnter = event.key === 'Enter';
    const isMenuOpen = selectRef.current.state.menuIsOpen;

    if (isEnter && !isMenuOpen) {
      submitRef.current.click();
    }
  };

  return (
    <>
      <ReactSelect
        className={props.className}
        closeMenuOnScroll
        inputId={props.id || props.name}
        isDisabled={props.isDisabled}
        menuPlacement={props.menuPlacement}
        name={props.name}
        onBlur={props.onBlur}
        onChange={handleChange}
        onFocus={props.onFocus}
        onKeyDown={handleKeyDown}
        options={props.options}
        placeholder={props.placeholder}
        ref={selectRef}
        styles={{
          container: (base, state) => ({
            ...base,
            backgroundColor: state.isDisabled ? '#f5f5f5' : '#fff',
            borderColor: props.isInvalid ? '#ba2425' : '#ececec',
            borderRadius: '3px',
            borderStyle: 'solid',
            borderWidth: '1px',
            boxShadow: 'inset 0 1px 1px rgba(0, 0, 0, 0.075)',
          }),
          control: (base, state) => ({
            ...base,
            background: 'none',
            border: 'none',
            boxShadow: 'none',
            height: CONTROL_HEIGHT[props.size],
            minHeight: CONTROL_HEIGHT[props.size],
            outline: state.isFocused ? '#ff9831 auto 5px' : 'none',
          }),
          dropdownIndicator: (base, state) => ({
            ...base,
            padding: '0 0.5rem',
            svg: {
              fill: props.isInvalid ? '#ba2425' : '#5a5a5a',
              opacity: state.isDisabled ? 0.25 : 'inherit',
            },
          }),
          indicatorSeparator: () => ({ display: 'none' }),
          input: (base) => ({
            ...base,
            fontSize: INPUT_FONT_SIZE[props.size],
            fontWeight: 300,
            margin: 0,
            padding: 0,
          }),
          singleValue: (base) => ({
            ...base,
            color: '#565657',
            fontSize: INPUT_FONT_SIZE[props.size],
            fontWeight: 300,
          }),
          menu: (base) => ({ ...base, zIndex: 1001 }),
          menuPortal: (base) => ({ ...base, zIndex: 1001 }),
          option: (base, state) => ({
            ...base,
            ':active': { backgroundColor: '#142d61', color: '#fff' },
            ':hover': { backgroundColor: '#142d61', color: '#fff' },
            backgroundColor:
              state.isSelected || state.isFocused ? '#142d61' : 'transparent',
            color: state.isSelected || state.isFocused ? '#fff' : 'inherit',
          }),
          placeholder: (base) => ({
            ...base,
            color: props.isInvalid ? '#ba2425' : '#a6a6a6',
            fontSize: INPUT_FONT_SIZE[props.size],
            fontWeight: 300,
            margin: 0,
            whiteSpace: 'nowrap',
          }),
          valueContainer: (base) => ({
            ...base,
            paddingBottom: 0,
            paddingLeft: VALUE_CONTAINER_PADDING[props.size],
            paddingRight: VALUE_CONTAINER_PADDING[props.size],
            paddingTop: 0,
          }),
        }}
        value={selected}
      />
      <button className={styles.hidden} ref={submitRef} type="submit" />
    </>
  );
};

SelectInput.defaultProps = {
  ...defaultInputProps,
  isDisabled: false,
  placeholder: undefined,
  menuPlacement: 'auto',
  size: SIZE_ENUM.M,
  options: [],
};

export default SelectInput;
