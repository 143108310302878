import React from 'react';
import cn from 'classnames';

import { Link } from '../../Router';
import { COMPANY_NAME } from '../../../../constants';

import styles from './Copyright.pcss';

interface ICopyrightsProps {
  className: string;
}

const Copyrights: React.FC<ICopyrightsProps> = (props) => (
  <div className={cn(styles.container, props.className)}>
    Copyright © {COMPANY_NAME} Inc. - All rights reserved
    <nav className={styles.nav}>
      <ul className={styles.list}>
        <li className={styles.item}>
          <Link to="/terms">Terms of Service</Link>
        </li>
        <li className={styles.item}>
          <Link to="/policy">Privacy Policy</Link>
        </li>
      </ul>
    </nav>
  </div>
);

Copyrights.defaultProps = {
  className: null,
};

export default Copyrights;
