import {
  FieldWrapperContext,
  wrapWithFieldWrapper,
  wrapWithFormControl,
  FormControlsConfigType,
} from '../../wrappers';

import AutosuggestLocationTitleInput, {
  SIZE_ENUM,
} from './AutosuggestLocationTitleInput';

const config: FormControlsConfigType<typeof AutosuggestLocationTitleInput> = {
  [FieldWrapperContext.layout.DEFAULT]: wrapWithFormControl(
    AutosuggestLocationTitleInput
  ),
};

export const AutosuggestLocationTitleControl = wrapWithFieldWrapper(config);

export default AutosuggestLocationTitleInput;
export { SIZE_ENUM };
