import {
  FieldWrapperContext,
  wrapWithFieldWrapper,
  wrapWithFormControl,
  FormControlsConfigType,
} from '../../wrappers';
import FunctionInput, { SIZE_ENUM } from './FunctionInput';

const config: FormControlsConfigType<typeof FunctionInput> = {
  [FieldWrapperContext.layout.DEFAULT]: wrapWithFormControl(FunctionInput),
};

export const FunctionControl = wrapWithFieldWrapper(config);

export default FunctionInput;

export { SIZE_ENUM };
