import React from 'react';

import { connect } from 'react-redux';
import { ACTION_KEY } from './constants';
import { ACTION_KEY as LANDING_PAGE_KEY } from '../constants';
import { loadSavedTopSearches } from './actions';

import TopSearchesArea from './TopSearchesArea';

interface ITopSearchesContainerProps {
  top_searches: any;
  loadSavedTopSearches(): any;
}

class TopSearchesContainer extends React.Component<ITopSearchesContainerProps> {
  componentDidMount() {
    this.props.loadSavedTopSearches();
  }

  render() {
    return <TopSearchesArea topSearches={this.props.top_searches} />;
  }
}

const mapStateToProps = (state) => ({
  top_searches: state[LANDING_PAGE_KEY][ACTION_KEY],
});

export default connect(mapStateToProps, { loadSavedTopSearches })(
  TopSearchesContainer
);
