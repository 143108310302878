import React from 'react';
import MediaQuery from 'react-responsive';

import BGBlue from '../../../partials/backgrounds/BGBlue';
import CenteredContent from '../../../partials/CenteredContent';
import AdSense from '../../../partials/widgets/AdSense';
import InfoGraphic from './InfoGraphic';
import { COMPANY_NAME } from '../../../../constants';

import styles from './AboutArea.pcss';

const AboutArea: React.FC = () => (
  <BGBlue>
    <CenteredContent>
      <div className={styles.container}>
        <div className={styles.subContainer}>
          <div className={styles.mainContainer}>
            <h4 className={styles.header}>About {COMPANY_NAME}</h4>
            <h6 className={styles.subHeader}>
              {COMPANY_NAME} is America
              {`'`}s number one job search organizer with more than 6 million
              members. Job seekers across the US turn to {COMPANY_NAME} to close
              the {'"'}
              resume black hole
              {'"'} by receiving automatic status updates from thousands of
              employers.
            </h6>
            <MediaQuery query="(max-height: 300px), (max-width: 960px), (orientation: portrait), (min-height: 500px)">
              <InfoGraphic />
            </MediaQuery>
          </div>
          <div className={styles.adContainer}>
            <AdSense />
          </div>
        </div>
        <MediaQuery query="(min-height: 300px) and (min-width: 960px) and (max-height: 500px)">
          <InfoGraphic />
        </MediaQuery>
      </div>
    </CenteredContent>
  </BGBlue>
);

export default AboutArea;
