import React from 'react';
import cn from 'classnames';
import Loader from '../Loader';

import styles from './NextLoader.pcss';

interface INextLoaderProps {
  className?: string;
  color?: string;
  indicator?: boolean;
  isPreloader?: boolean;
  size?: string;
  background?: string;
  isFullPage?: boolean;
}

const NextLoader: React.FC<INextLoaderProps> = (props) => {
  const {
    children,
    background,
    indicator,
    color,
    size,
    className,
    isPreloader,
    isFullPage,
  } = props;

  return (
    <div className={cn(styles.container, className)}>
      {indicator && (
        <div className={styles.loaderContainer} style={{ background }}>
          <Loader
            className={cn({ [styles.fullPageLoader]: isFullPage })}
            color={color}
            size={size}
          />
        </div>
      )}
      {(!indicator || !isPreloader) && children}
    </div>
  );
};

NextLoader.defaultProps = {
  className: null,
  color: '#142d61',
  indicator: false,
  isPreloader: false,
  size: '.5',
  background: 'rgba(255, 255, 255, 0.75)',
  isFullPage: false,
};

export default NextLoader;
