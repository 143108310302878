import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import Ad from './Ad';
import Placeholder from './Placeholder/Placeholder';
import { useUrlMethods } from '../../../../context/withUrlMethods';
import { AD_ENVS, AD_SLOT } from './constants';
import type { AdFormat } from './types';

import styles from './AdSense.pcss';

type AdSenseProps = {
  className?: string;
  slot?: AD_SLOT;
} & (
  | {
      type?: 'BANNER';
      format?: AdFormat;
    }
  | {
      type: 'IN_FEED';
    }
);

const AdSense: React.FC<AdSenseProps> = (props) => {
  const [uuid, setUuid] = useState(uuidv4());
  const { location } = useUrlMethods();

  useEffect(() => {
    setUuid(uuidv4());
  }, [location.pathname]);

  return (
    <div
      key={`adsense-${uuid}`}
      className={cn(styles.container, props.className)}
    >
      {AD_ENVS.includes(process.env.ENV) ? (
        <Ad {...props} />
      ) : (
        <Placeholder {...props} />
      )}
    </div>
  );
};

AdSense.defaultProps = {
  className: undefined,
  type: 'BANNER',
  format: 'W300H250',
  slot: null,
};

export default AdSense;
