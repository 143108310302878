import React from 'react';

import BGDarkBlue from '../../../partials/backgrounds/BGDarkBlue';
import CenteredContent from '../../../partials/CenteredContent';
import AppTrackingFormContainer from './AppTrackingForm';
import AppTrackingFooter from './ATSAreaFooter';
import { COMPANY_NAME } from '../../../../constants';

import styles from './AppTrackingArea.pcss';

const AppTrackingArea: React.FC = () => (
  <BGDarkBlue>
    <div className={styles.container} id="ats_area">
      <CenteredContent>
        <h4 className={styles.header}>
          America
          {`'`}s #1 Job Search Organizer
        </h4>
        <h6 className={styles.subHeader}>
          Even if you didn
          {`'`}t apply from {COMPANY_NAME}, we can track the status of your
          application and help you to stay organized.
        </h6>
        <AppTrackingFormContainer />
        <AppTrackingFooter />
      </CenteredContent>
    </div>
  </BGDarkBlue>
);

export default AppTrackingArea;
