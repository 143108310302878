import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Collapse from '../../../utils/collapse';
import { toggle, isCollapsed } from './actions';
import { ACTION_KEY } from './constants';

interface ICollapsableProps {
  className?: string;
  visibleComponents?: any;
  id: string;
  open?: boolean;
  children: React.ReactNode;
}

class Collapsable extends React.Component<ICollapsableProps> {
  static defaultProps = {
    className: undefined,
  };

  isCollapsed() {
    const { visibleComponents, id } = this.props;
    return isCollapsed(visibleComponents, id);
  }

  render() {
    const { className, children, open } = this.props;

    return (
      <div className={className}>
        <Collapse isOpened={open || !this.isCollapsed()}>{children}</Collapse>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ visibleComponents: state[ACTION_KEY] });

const mapDispatchToProps = (dispatch) => ({
  toggle: bindActionCreators(toggle, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Collapsable);
