import {
  FieldWrapperContext,
  wrapWithFieldWrapper,
  wrapWithFormControl,
  FormControlsConfigType,
} from '../../wrappers';

import AutosuggestCompanyTitleInput from './AutosuggestCompanyTitleInput';

const config: FormControlsConfigType<typeof AutosuggestCompanyTitleInput> = {
  [FieldWrapperContext.layout.DEFAULT]: wrapWithFormControl(
    AutosuggestCompanyTitleInput
  ),
};

export const AutosuggestCompanyTitleControl = wrapWithFieldWrapper(config);

export default AutosuggestCompanyTitleInput;
