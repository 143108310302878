import React from 'react';
import { connect } from 'react-redux';
import { FaBars, FaSearch } from 'react-icons/fa';
import classNames from 'classnames/bind';

import Logo from '../../Logo';
import CollapsableToggleButtonContainer from '../../Collapsable/CollapsableToggleButton';
import ToggleMenuButtonContainer from './MobileMenu/ToggleMenuButtonContainer';
import MobileMenu from './MobileMenu';

import s from './MobileHeader.pcss';

const cx = classNames.bind(s);

const searchButtonStyle = (
  current_user,
  noSearchButton
): React.CSSProperties => ({
  ...(!current_user ? { position: 'absolute', right: 0 } : undefined),
  ...(noSearchButton ? { visibility: 'hidden' } : undefined),
});

interface IMobileHeaderProps {
  current_user: any;
  customNavigation?: React.ReactNode;
  noSearchForm: boolean;
}

const MobileHeader: React.FC<IMobileHeaderProps> = ({
  current_user,
  customNavigation,
  noSearchForm,
}) => (
  <div
    className={cx('container', {
      logoLeft: !!current_user || !!customNavigation,
    })}
  >
    {current_user ? (
      <>
        <div className={s.menu}>
          <ToggleMenuButtonContainer toggleComponent={() => <FaBars />} />
        </div>
        <MobileMenu />
        <div className={s.logo}>
          <Logo is_authorized withoutText />
        </div>
      </>
    ) : (
      <Logo />
    )}
    {customNavigation}
    <div
      className={s.search}
      style={searchButtonStyle(current_user, noSearchForm)}
    >
      <CollapsableToggleButtonContainer
        id="searchFormBlock"
        toggleComponent={() => <FaSearch />}
      />
    </div>
  </div>
);

const mapStateToProps = (state) => ({
  current_user: state.auth.session.current_user,
});

export default connect(mapStateToProps)(MobileHeader);
