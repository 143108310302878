import React, { PureComponent } from 'react';
import classNames from 'classnames/bind';

import { Link } from '../Router';

import styles from './Logo.pcss';

const cx = classNames.bind(styles);

interface ILogoProps {
  className?: string;
  withoutText?: boolean;
  onClick?(): any;
  is_authorized?: boolean;
}

class Logo extends PureComponent<ILogoProps> {
  static defaultProps = {
    className: null,
  };

  render() {
    const { className, withoutText, onClick, is_authorized } = this.props;

    return (
      <Link
        to={is_authorized ? '/home' : '/'}
        onClick={() => {
          if (onClick) {
            onClick();
          }
        }}
        className={cx(
          withoutText ? 'withoutTextContainer' : 'withTextContainer',
          className
        )}
        withouthDecoration
      >
        <div
          className={cx(
            withoutText ? 'withoutTextIcon' : 'withTextIcon',
            'icon'
          )}
        />
        {!withoutText && <div className={styles.text}>startwire</div>}
      </Link>
    );
  }
}

export default Logo;
