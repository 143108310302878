import React from 'react';

import { useSelector, useDispatch } from '../../../../store';
import useEffectIf from '../../../../common/hooks/useEffectIf';
import { ACTION_KEY as LANDING_PAGE_KEY } from '../constants';
import { ACTION_KEY } from './constants';
import { loadNewBackground } from './actions';

import SmartBackground from './SmartBackground';

const SmartBackgroundContainer: React.FC = (props) => {
  const dispatch = useDispatch();
  const { bg_key, bg_url } = useSelector(
    (state) => state[LANDING_PAGE_KEY][ACTION_KEY]
  );

  useEffectIf(() => {
    dispatch(loadNewBackground(bg_key));
  }, [bg_key]);

  return (
    <SmartBackground bgKey={bg_key} bgUrl={bg_url}>
      {props.children}
    </SmartBackground>
  );
};

export default SmartBackgroundContainer;
