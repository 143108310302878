import React from 'react';
import { AD_SIZE } from '../../constants';
import type { AdFormat } from '../../types';

type PlaceholderProps = {
  format?: AdFormat;
};

const Placeholder: React.FC<PlaceholderProps> = (props) => {
  const { height, width } = AD_SIZE[props.format];

  return (
    <div
      style={{
        display: 'block',
        height,
        width,
      }}
    />
  );
};

Placeholder.defaultProps = {
  format: 'W300H250',
};

export default Placeholder;
