import React from 'react';

import { PopupSwitch, PopupRoute } from './components/partials/Router';

import SignInLazyPopup from './components/popups/authentication/SignInPopup';
import { POPUP_KEY as SIGN_IN_POPUP_KEY } from './components/popups/authentication/SignInPopup/constants';
import HelpDeskLazyPopup from './components/popups/HelpDesk';
import { POPUP_KEY as HELP_DESK_POPUP_KEY } from './components/popups/HelpDesk/constants';
import PasswordUpdateLazyPopup from './components/popups/authentication/PasswordUpdatePopup';
import { POPUP_KEY as PASSWORD_UPDATE_POPUP_KEY } from './components/popups/authentication/PasswordUpdatePopup/constants';
import PasswordResetRequestLazyPopup from './components/popups/authentication/PasswordResetRequestPopup';
import { POPUP_KEY as PASSWORD_RESET_REQUEST_POPUP_KEY } from './components/popups/authentication/PasswordResetRequestPopup/constants';
import SignUpLazyPopup from './components/popups/authentication/SignUpPopup';
import { POPUP_KEY as SIGN_UP_POPUP_KEY } from './components/popups/authentication/SignUpPopup/constants';
import ResumeLazyPopup from './components/popups/Resume';
import { POPUP_KEY as RESUME_POPUP_KEY } from './components/popups/Resume/constants';
import OnBoardingResumeLazyPopup from './components/popups/OnBoardingResume';
import { POPUP_KEY as ON_BOARDING_RESUME_POPUP_KEY } from './components/popups/OnBoardingResume/constants';
import OffersLazyPopup from './components/popups/Offers';
import { POPUP_KEY as OFFERS_POPUP_KEY } from './components/popups/Offers/constants';
import AutoAcceptOffersLazyPopup from './components/popups/AutoAcceptOffers';
import { POPUP_KEY as AUTO_ACCEPT_OFFERS_POPUP_KEY } from './components/popups/AutoAcceptOffers/constants';
import QuickOffersLazyPopup from './components/popups/QuickOffers';
import { POPUP_KEY as QUICK_OFFERS_POPUP_KEY } from './components/popups/QuickOffers/constants';
import AutoStatusUpdateLazyPopup from './components/popups/AutoStatusUpdate';
import { POPUP_KEY as AUTO_STATUS_UPDATES_POPUP_KEY } from './components/popups/AutoStatusUpdate/constants';
import AutoStatusUpdatesLazyPopup from './components/popups/AutoStatusUpdates';
import ATSAppsLazyPopup from './components/popups/ATSApps';
import { POPUP_KEY as ATS_APPS_POPUP_KEY } from './components/popups/ATSApps/constants';
import AdditionalQuestionsLazyPopup from './components/popups/AdditionalQuestions';
import { POPUP_KEY as ADDITIONAL_QUESTIONS_POPUP_KEY } from './components/popups/AdditionalQuestions/constants';
import ExpressApplyLazyPopup from './components/popups/ExpressApply';
import { POPUP_KEY as EXPRESS_APLY_POPUP_KEY } from './components/popups/ExpressApply/constants';
import ExpressApplyJobsLazyPopup from './components/popups/ExpressApplyJobs';
import { POPUP_KEY as EXPRESS_APLY_JOBS_POPUP_KEY } from './components/popups/ExpressApplyJobs/constants';
import ReviewLazyPopup from './components/popups/Review';
import { POPUP_KEY as REVIEW_POPUP_KEY } from './components/popups/Review/constants';
import ActionLazyPopup from './components/pages/JobsUa/popups/Action';
import { POPUP_KEY as ACTION_POPUP_KEY } from './components/pages/JobsUa/popups/Action/constants';

const PopupRoutes: React.FC = () => (
  <PopupSwitch>
    <PopupRoute
      path={SIGN_IN_POPUP_KEY}
      component={SignInLazyPopup}
      getParams={(queryParams) => ({
        email: queryParams[SIGN_IN_POPUP_KEY],
      })}
    />
    <PopupRoute
      path={SIGN_UP_POPUP_KEY}
      component={SignUpLazyPopup}
      getParams={(queryParams) => ({
        email: queryParams[SIGN_UP_POPUP_KEY],
        name: queryParams.name,
        from: queryParams.form,
      })}
    />
    <PopupRoute
      path={PASSWORD_RESET_REQUEST_POPUP_KEY}
      component={PasswordResetRequestLazyPopup}
      getParams={(queryParams) => ({
        email: queryParams[PASSWORD_RESET_REQUEST_POPUP_KEY],
      })}
    />
    <PopupRoute
      path={PASSWORD_UPDATE_POPUP_KEY}
      component={PasswordUpdateLazyPopup}
      getParams={(queryParams) => ({
        token: queryParams[PASSWORD_UPDATE_POPUP_KEY],
      })}
    />
    <PopupRoute
      path={OFFERS_POPUP_KEY}
      component={OffersLazyPopup}
      getParams={(queryParams) => ({
        source: queryParams[OFFERS_POPUP_KEY],
      })}
    />
    <PopupRoute
      path={QUICK_OFFERS_POPUP_KEY}
      component={QuickOffersLazyPopup}
      getParams={(queryParams) => ({
        source: queryParams[QUICK_OFFERS_POPUP_KEY],
      })}
    />
    <PopupRoute
      path={AUTO_ACCEPT_OFFERS_POPUP_KEY}
      component={AutoAcceptOffersLazyPopup}
    />
    <PopupRoute
      path={ADDITIONAL_QUESTIONS_POPUP_KEY}
      component={AdditionalQuestionsLazyPopup}
    />
    <PopupRoute
      path={EXPRESS_APLY_JOBS_POPUP_KEY}
      component={ExpressApplyJobsLazyPopup}
    />
    <PopupRoute
      path={EXPRESS_APLY_POPUP_KEY}
      component={ExpressApplyLazyPopup}
      getParams={(queryParams) => ({
        id: queryParams[EXPRESS_APLY_POPUP_KEY],
      })}
    />
    <PopupRoute
      path={'ats'}
      component={AutoStatusUpdatesLazyPopup}
      getParams={(queryParams) => ({
        id: queryParams.ats ? parseInt(queryParams.ats, 10) : null,
        byCompany: false,
      })}
    />
    <PopupRoute
      path={'auto-company-status-updates'}
      component={AutoStatusUpdatesLazyPopup}
      getParams={(queryParams) => {
        const value = queryParams['auto-company-status-updates'];
        return {
          id: value ? parseInt(value, 10) : null,
          byCompany: true,
        };
      }}
    />
    <PopupRoute
      path={'auto-status-updates'}
      component={AutoStatusUpdatesLazyPopup}
      getParams={(queryParams) => {
        const value = queryParams['auto-status-updates'];
        return {
          id: value ? parseInt(value, 10) : null,
          byCompany: false,
        };
      }}
    />
    <PopupRoute
      path={AUTO_STATUS_UPDATES_POPUP_KEY}
      component={AutoStatusUpdateLazyPopup}
      getParams={(queryParams) => ({
        companyId: queryParams[AUTO_STATUS_UPDATES_POPUP_KEY],
      })}
    />
    <PopupRoute path={ATS_APPS_POPUP_KEY} component={ATSAppsLazyPopup} />
    <PopupRoute
      path={REVIEW_POPUP_KEY}
      component={ReviewLazyPopup}
      getParams={(queryParams) => ({
        id: queryParams[REVIEW_POPUP_KEY],
      })}
    />
    <PopupRoute path={RESUME_POPUP_KEY} component={ResumeLazyPopup} />
    <PopupRoute
      path={ON_BOARDING_RESUME_POPUP_KEY}
      component={OnBoardingResumeLazyPopup}
    />
    <PopupRoute
      path={ACTION_POPUP_KEY}
      component={ActionLazyPopup}
      getParams={(queryParams) => ({
        action: queryParams[ACTION_POPUP_KEY],
      })}
    />
    <PopupRoute path={HELP_DESK_POPUP_KEY} component={HelpDeskLazyPopup} />
  </PopupSwitch>
);

export default PopupRoutes;
