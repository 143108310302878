import React from 'react';
import cn from 'classnames';
import { AD_SIZE } from '../constants';
import type { AdFormat } from '../types';

import styles from './Placeholder.pcss';

type PlaceholderProps =
  | {
      type?: 'BANNER';
      format?: AdFormat;
    }
  | {
      type: 'IN_FEED';
    };

const Placeholder: React.FC<PlaceholderProps> = (props) => {
  switch (props.type) {
    case 'IN_FEED':
      return (
        <div
          className={cn(
            styles.placeholder,
            styles[`placeholder_env_${process.env.ENV}`],
            styles.placeholder_full_width
          )}
        />
      );
    case 'BANNER':
    default: {
      const { width } = AD_SIZE[props.format];
      return (
        <div
          className={cn(
            styles.placeholder,
            styles[`placeholder_env_${process.env.ENV}`],
            [styles[`placeholder_width_${width}`]]
          )}
        />
      );
    }
  }
};

export default Placeholder;
