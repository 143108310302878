import {
  FieldWrapperContext,
  wrapWithFieldWrapper,
  wrapWithFormControl,
  FormControlsConfigType,
} from '../../wrappers';

import AutosuggestJobTitle from './AutosuggestJobTitle';

const config: FormControlsConfigType<typeof AutosuggestJobTitle> = {
  [FieldWrapperContext.layout.DEFAULT]: wrapWithFormControl(
    AutosuggestJobTitle
  ),
};

export const AutosuggestJobTitleControl = wrapWithFieldWrapper(config);

export default AutosuggestJobTitle;
