import React, { useCallback } from 'react';
import cn from 'classnames';

import type { IMarkupProps } from '../types';

const Markup: React.FC<IMarkupProps> = (props) => {
  const modifyChildren = useCallback((child) => {
    const className = cn(child.props.className, props.className);

    const newProps = {
      ...child.props,
      className,
    };

    return React.cloneElement(child, newProps);
  }, []);

  return <>{React.Children.map(props.children, modifyChildren)}</>;
};

export default Markup;
