import React from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { SW } from '@types';
import AutosuggestInput, { SIZE_ENUM } from '../Autosuggest';

type AutosuggestLocationTitleInputProps = SW.Utils.React.PatchProps<
  typeof AutosuggestInput,
  { hasIcon?: boolean },
  'placeholder' | 'icon' | 'getSuggestionValue' | 'query'
>;

const getSuggestionValue = ({ title }) => title;

const AutosuggestLocationTitleInput: AutosuggestLocationTitleInputProps = ({
  hasIcon,
  ...rest
}) => (
  <AutosuggestInput
    getSuggestionValue={getSuggestionValue}
    icon={hasIcon ? FaMapMarkerAlt : null}
    placeholder="Location"
    query="location_title"
    {...rest}
  />
);

AutosuggestLocationTitleInput.defaultProps = {
  hasIcon: false,
};

export default AutosuggestLocationTitleInput;
export { SIZE_ENUM };
