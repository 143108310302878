import React from 'react';
import { SW } from '@types';

import SuggestInput, { SIZE_ENUM } from '../Suggest';
import { useHttpClient } from '../../../../../context/withHttpClient';

type AutosuggestInputType = SW.Utils.React.PatchProps<
  typeof SuggestInput,
  { query: string },
  'suggestionsProvider'
>;

const AutosuggestInput: AutosuggestInputType = (props) => {
  const httpClient = useHttpClient();
  return (
    <SuggestInput
      suggestionsProvider={(value) =>
        httpClient
          .get(`suggest/${props.query}`, { q: value })
          .then((response) =>
            response.success ? response.suggestions : Promise.reject()
          )
      }
      {...props}
    />
  );
};

export default AutosuggestInput;
export { SIZE_ENUM };
