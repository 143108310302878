import React from 'react';
import { SW } from '@types';
import SelectInput, { SIZE_ENUM } from '../Select/SelectInput';
import functions from '../../../../../../caches/functions_for_select.json';

type FunctionInputType = SW.Utils.React.OmitProps<
  typeof SelectInput,
  'options'
>;

const FunctionInput: FunctionInputType = (props) => (
  <SelectInput {...props} options={functions} />
);

FunctionInput.defaultProps = {
  placeholder: 'You Function',
};

export default FunctionInput;

export { SIZE_ENUM };
