import React from 'react';

import styles from './PeopleStoryChooser.pcss';

interface IPeopleStoryChooserProps {
  index: number;
  size: number;
  setIndex(i): any;
}

const PeopleStoryChooser: React.FC<IPeopleStoryChooserProps> = ({
  size,
  index,
  setIndex,
}) => (
  <div className={styles.container}>
    {Object.keys([...Array(size)]).map((i) => (
      <div
        key={i}
        className={index === parseInt(i, 10) ? styles.active : styles.passive}
        onClick={() => setIndex(i)}
        onKeyDown={() => setIndex(i)}
        role="presentation"
      />
    ))}
  </div>
);

export default PeopleStoryChooser;
