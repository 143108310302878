import React, { useContext } from 'react';
import { connect } from 'react-redux';

import { LayerContext } from '../../../../layouts/Base/Layer';
import toggleMenu from './actions';
import { ACTION_KEY } from './constants';
import withUrlMethods from '../../../../../context/withUrlMethods/withUrlMethods';

interface IToggleMenuButtonContainerProps {
  toggleComponent(): any;
  isOpened: boolean;
  toggleMenuAction(status, path, changeLocation, isPage, layer): any;
  changeLocation(): any;
  isPage(path): boolean;
}

const ToggleMenuButtonContainer: React.FC<IToggleMenuButtonContainerProps> = ({
  toggleMenuAction,
  isOpened,
  toggleComponent,
  changeLocation,
  isPage,
}) => {
  const layer = useContext(LayerContext);
  const toggle = (status, path) => () =>
    toggleMenuAction(status, path, changeLocation, isPage, layer);
  return (
    <div
      onClick={toggle(isOpened, null)}
      onKeyDown={toggle(isOpened, null)}
      role="presentation"
    >
      {toggleComponent()}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isOpened: state[ACTION_KEY].isOpened,
});

const mapDispatchToProps = (dispatch) => ({
  toggleMenuAction: (status, path, changeLocation, isPage, layer) =>
    dispatch(toggleMenu(status, path, changeLocation, isPage, layer)),
});

export default withUrlMethods(['changeLocation', 'isPage'])(
  connect(mapStateToProps, mapDispatchToProps)(ToggleMenuButtonContainer)
);
