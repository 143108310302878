import {
  FieldWrapperContext,
  wrapWithFieldWrapper,
  wrapWithFormControl,
  FormControlsConfigType,
} from '../../wrappers';

import SuggestInput, { SIZE_ENUM } from './SuggestInput';

const config: FormControlsConfigType<typeof SuggestInput> = {
  [FieldWrapperContext.layout.DEFAULT]: wrapWithFormControl(SuggestInput),
};

export const SuggestControl = wrapWithFieldWrapper(config);

export { SIZE_ENUM };

export default SuggestInput;
