import React from 'react';

export enum FIELD_WRAPPER_LAYOUT {
  DEFAULT = 'default',
}

interface IFieldWrapperContext extends React.Context<FIELD_WRAPPER_LAYOUT> {
  layout: typeof FIELD_WRAPPER_LAYOUT;
}

const FieldWrapperContext: IFieldWrapperContext = React.createContext<
  FIELD_WRAPPER_LAYOUT
>(FIELD_WRAPPER_LAYOUT.DEFAULT) as IFieldWrapperContext;

FieldWrapperContext.layout = FIELD_WRAPPER_LAYOUT;

export default FieldWrapperContext;
