import React from 'react';
import classNames from 'classnames/bind';

import { Link } from '../../../../Router';

import styles from './HeaderPageLink.pcss';
import { useUrlMethods } from '../../../../../../context/withUrlMethods';

const cx = classNames.bind(styles);

interface IHeaderPageLinkProps {
  children: string;
  path: string;
  altPaths?: string[];
}

const HeaderPageLink: React.FC<IHeaderPageLinkProps> = (props) => {
  const { children, path, altPaths } = props;
  const { isPage } = useUrlMethods();

  return (
    <Link
      className={cx('container', {
        active: isPage(path) || altPaths.some(isPage),
      })}
      to={path}
      withouthDecoration
    >
      {children}
    </Link>
  );
};

HeaderPageLink.defaultProps = {
  altPaths: [],
};

export default HeaderPageLink;
