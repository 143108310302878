import {
  FieldWrapperContext,
  wrapWithFieldWrapper,
  wrapWithFormControl,
  FormControlsConfigType,
} from '../../wrappers';

import SwitchInput from './SwitchInput';

const config: FormControlsConfigType<typeof SwitchInput> = {
  [FieldWrapperContext.layout.DEFAULT]: wrapWithFormControl(SwitchInput),
};

export const SwitchControl = wrapWithFieldWrapper(config);

export default SwitchInput;
