import * as Sentry from '@sentry/react';
import { SET_STATUS, STATUS } from './constants';
import { IS_CAPTURE_SENTRY_ERRROR_ON_UNSUCCEED_SCRIPT_LOADING } from '../../../constants';

export const setScriptStatus = (src: string, status: STATUS) => {
  if (
    status === STATUS.FAILED &&
    IS_CAPTURE_SENTRY_ERRROR_ON_UNSUCCEED_SCRIPT_LOADING
  ) {
    Sentry.captureMessage(
      `Unsuccessful script loading (${src})`,
      Sentry.Severity.Error
    );
  }
  return {
    type: SET_STATUS,
    payload: { src, status },
  };
};
