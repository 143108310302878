import React from 'react';
import { SW } from '@types';
import TextInput, { SIZE_ENUM, TYPE_ENUM } from '../Text';

type EmailInputType = SW.Utils.React.OmitProps<typeof TextInput, 'type'>;

const EmailInput: EmailInputType = (props) => (
  <TextInput {...props} type={TYPE_ENUM.EMAIL} />
);

EmailInput.defaultProps = {
  placeholder: 'Email Address',
};

export default EmailInput;

export { SIZE_ENUM };
