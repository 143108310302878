import React from 'react';
import { SW } from '@types';
import { FormControlProps } from '../FormControl/FormControlProps';
import { AdapterProps, RestFormControlProps } from './AdapterProps';

const reduxFormAdapter = <TVal, T extends SW.Form.Input.IProps<TVal>>(
  Component: React.ComponentType<FormControlProps<TVal, T>>
) => {
  const Adapter: React.FC<AdapterProps<TVal, T>> = (props) => {
    const { input, meta, onChange, onBlur, onFocus } = props;

    const handleBlur = () => {
      onBlur();
      input.onBlur(undefined);
    };

    const handleChange = (value: TVal) => {
      onChange(value);
      input.onChange(value);
    };

    const handleFocus = (event?: React.FocusEvent) => {
      onFocus();
      input.onFocus(event);
    };

    const restFormControlProps = props as RestFormControlProps<TVal, T>;

    const componentProps = {
      ...restFormControlProps,
      id: `${meta.form}_${input.name}`,
      name: input.name,
      onBlur: handleBlur,
      onChange: handleChange,
      onFocus: handleFocus,
      isInvalid: meta.error && meta.touched,
      value: input.value,
      error: meta.error,
    } as FormControlProps<TVal, T>;

    return <Component {...componentProps} />;
  };

  Adapter.defaultProps = {
    onChange: () => {},
    onBlur: () => {},
    onFocus: () => {},
  } as Partial<AdapterProps<TVal, T>>;

  return Adapter;
};

export default reduxFormAdapter;
