import React from 'react';
import cn from 'classnames';
import isArray from 'lodash/isArray';

import type { IMarkupProps } from '../types';

import styles from './Markup.pcss';

const Markup: React.FC<IMarkupProps> = (props) => {
  const Wrapper = props.hasEmptyLabel ? 'div' : 'label';

  return (
    <div className={props.className} style={props.style}>
      <Wrapper
        className={cn({ [styles.fake]: props.hasEmptyLabel })}
        htmlFor={props.id}
      >
        {props.label && !props.hasEmptyLabel && (
          <div className={cn(styles.label, props.theme.label)}>
            {props.label}
          </div>
        )}
        <div className={styles.control}>{props.children}</div>
      </Wrapper>
      {props.isInvalid && (
        <div className={cn(styles.error, props.theme.error)}>
          {isArray(props.error) ? props.error[0] : props.error}
        </div>
      )}
    </div>
  );
};

Markup.defaultProps = {
  className: undefined,
  style: undefined,
  error: null,
  hasEmptyLabel: false,
  isInvalid: false,
  label: null,
  theme: {
    error: undefined,
    label: undefined,
  },
};

export default Markup;
