import React from 'react';

import Button, { IButtonProps } from '../../Button';
import { useFormContext } from '../FormContext';

type ISubmitProps = Omit<
  IButtonProps<'button'>,
  'as' | 'type' | 'dots' | 'handleDots' | 'handleDisabling'
>;

const Submit: React.FC<ISubmitProps> = (props) => {
  const { children, disabled, ...restProps } = props;
  const { isSubmitting } = useFormContext();

  return (
    <Button
      type="submit"
      {...restProps}
      dots={isSubmitting}
      disabled={disabled || isSubmitting}
    >
      {children}
    </Button>
  );
};

export default Submit;
