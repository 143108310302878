import React from 'react';
import { Link, PopupLink, HashLink, ExtLink } from '../../Router';

import { COMPANY_NAME } from '../../../../constants';

import styles from './Navigation.pcss';

interface IMenuItem<T = any> {
  component: React.ComponentType<T>;
  props: T;
  text: string;
}

const MENU_ITEMS: IMenuItem[] = [
  {
    component: Link,
    props: { to: '/about' },
    text: `About ${COMPANY_NAME}`,
  },
  {
    component: Link,
    props: { to: '/advertise' },
    text: 'Advertise',
  },
  {
    component: ExtLink,
    props: {
      to: 'http://blog.startwire.com',
      rel: 'noopener noreferrer',
      target: '_blank',
    },
    text: 'Blog',
  },
  {
    component: Link,
    props: { to: '/job-applications' },
    text: 'Top Companies Hiring',
  },
  {
    component: HashLink,
    props: { to: '/policy#ccpa' },
    text: 'California Privacy Rights',
  },
  {
    component: Link,
    props: { to: '/consumer-request' },
    text: 'Do not sell my personal information',
  },
  {
    component: PopupLink,
    props: { name: 'help-desk' },
    text: 'Support',
  },
];

interface INavigationProps {
  className: string;
}

const Navigation: React.FC<INavigationProps> = ({ className }) => (
  <nav className={className}>
    <ul>
      {MENU_ITEMS.map((item) => (
        <li key={item.text} className={styles.item}>
          {React.createElement(item.component, item.props, item.text)}
        </li>
      ))}
    </ul>
  </nav>
);

Navigation.defaultProps = {
  className: null,
};

export default Navigation;
