import {
  FieldWrapperContext,
  wrapWithFieldWrapper,
  wrapWithFormControl,
  wrapWithReduxFormField,
  ReduxFormFieldsConfigType,
} from '../../wrappers';

import FunctionField from './FunctionField';
import FunctionInput, { SIZE_ENUM } from '../../inputs/Function';

const config: ReduxFormFieldsConfigType<typeof FunctionInput> = {
  [FieldWrapperContext.layout.DEFAULT]: wrapWithReduxFormField(
    FunctionInput,
    FunctionField,
    wrapWithFormControl
  ),
};

export const FunctionControl = wrapWithFieldWrapper(config);

export { SIZE_ENUM };
