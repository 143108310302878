import React, { useState } from 'react';
import cn from 'classnames';

import useEffectIf from '../../../../../common/hooks/useEffectIf';

import styles from './SmartBackground.pcss';

interface ISmartBackgroundProps {
  bgKey: string;
  bgUrl: string;
}

const SmartBackground: React.FC<ISmartBackgroundProps> = (props) => {
  const [activeBg, setActiveBg] = useState<null | 'alpha' | 'beta'>(
    props.bgUrl ? 'alpha' : null
  );
  const [alphaBgUrl, setAlphaBgUrl] = useState<string>(props.bgUrl);
  const [betaBgUrl, setBetaBgUrl] = useState<string>(null);

  useEffectIf(() => {
    if (activeBg === 'alpha') {
      if (props.bgUrl !== alphaBgUrl) {
        setBetaBgUrl(props.bgUrl);
        setActiveBg('beta');
      }
    } else if (activeBg === 'beta') {
      setAlphaBgUrl(props.bgUrl);
      setActiveBg('alpha');
    } else {
      setBetaBgUrl(props.bgUrl);
      setActiveBg('beta');
    }
  }, [props.bgUrl]);

  return (
    <div className={cn(styles.container, styles.containerDynamic)}>
      <div>
        <div
          className={cn(styles.background, styles.alpha, {
            [styles.active]: activeBg === 'alpha',
            [styles.passive]: activeBg === 'beta',
          })}
          style={alphaBgUrl && { backgroundImage: `url(${alphaBgUrl})` }}
        />
        <div
          className={cn(styles.background, styles.beta, {
            [styles.active]: activeBg === 'beta',
            [styles.passive]: activeBg === 'alpha',
          })}
          style={betaBgUrl && { backgroundImage: `url(${betaBgUrl})` }}
        />
        <div className={styles.grayFilter} />
      </div>
      <div className={styles.content}>{props.children}</div>
    </div>
  );
};

export default SmartBackground;
