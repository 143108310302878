import React from 'react';
import { connect } from 'react-redux';

import Notice from '../FlashNotifications/Notice';

interface IPretendNotificationProps {
  isPretending: boolean;
}

const PretendNotification: React.FC<IPretendNotificationProps> = ({
  isPretending,
}) =>
  isPretending ? (
    <Notice content="You're in the Admin Users view." type="notice" />
  ) : null;

const mapStateToProps = ({ auth }) => {
  const { is_pretending = false } = auth.session.current_user || {};
  return {
    isPretending: is_pretending,
  };
};

export default connect(mapStateToProps)(PretendNotification);
