import React from 'react';
import cn from 'classnames';
import CenteredContent from '../../../partials/CenteredContent';
import { useUrlMethods } from '../../../../context/withUrlMethods';
import { COMPANY_NAME } from '../../../../constants';

import s from './Accelerate.pcss';

const Accelerate: React.FC = () => {
  const { isPage } = useUrlMethods();
  const page =
    isPage('upward') ||
    isPage('registrant') ||
    isPage('light') ||
    isPage('auto');

  return (
    <div
      className={cn(s.capabilitiesContainer, {
        [page]: page,
      })}
    >
      <CenteredContent>
        <h3>How {COMPANY_NAME} can help accelerate your job search</h3>
        <div className={s.capabilities}>
          <div>
            <div className={s.icon} />
            <b>Millions of jobs from every employer & job site</b>
            <span>
              We
              {`'`}
              ll present you with relevant jobs from thousands of employers that
              match your skills & experience.
            </span>
          </div>
          <div>
            <div className={s.icon} />
            <b>Quickly apply from any device, even your phone</b>
            <span>
              Submit your applications in less than 60 seconds. Spend less time
              filling out job applications and more time getting hired.
            </span>
          </div>
          <div>
            <div className={s.icon} />
            <b>Get found by recruiters, today!</b>
            <span>
              Share your resume with companies and recruiters looking for people
              just like you. You have complete control.
            </span>
          </div>
        </div>
      </CenteredContent>
    </div>
  );
};

export default Accelerate;
