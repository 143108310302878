import React from 'react';
import { SW } from '@types';
import AutosuggestInput from '../Autosuggest';
import Suggestion from './Suggestion';

type AutosuggestCompanyTitleInputType = SW.Utils.React.OmitProps<
  typeof AutosuggestInput,
  'getSuggestionValue' | 'query' | 'renderSuggestion'
>;

const getSuggestionValue = ({ title }) => title;

const AutosuggestCompanyTitleInput: AutosuggestCompanyTitleInputType = (
  props
) => (
  <AutosuggestInput
    getSuggestionValue={getSuggestionValue}
    placeholder={props.placeholder}
    query="company_title"
    renderSuggestion={Suggestion}
    {...props}
  />
);

AutosuggestCompanyTitleInput.defaultProps = {
  placeholder: 'Company',
};

export default AutosuggestCompanyTitleInput;
