import { TOGGLE } from './constants';

const toggleMenu = (isOpened, path, changeLocation, isPage, layer) => {
  if (isOpened) {
    layer.enable();
  } else {
    layer.disable();
  }

  if (path && !isPage(path)) {
    changeLocation(path);
  }

  return { type: TOGGLE, payload: !isOpened };
};

export default toggleMenu;
