import React from 'react';
import cn from 'classnames';

import styles from './Card.pcss';

type ICardTheme =
  | 'default'
  | 'border'
  | 'transparent'
  | 'success'
  | 'warn'
  | 'error';

interface ICardProps {
  className?: string;
  theme?: ICardTheme;
}

const Card: React.FC<ICardProps> = (props) => {
  const { children, className, theme } = props;
  return (
    <div className={cn(styles.card, styles[`card_theme_${theme}`], className)}>
      {children}
    </div>
  );
};

Card.defaultProps = {
  className: null,
  theme: 'default',
};

export default Card;
