import { SW } from '@types';

const defaultHeadMeta: SW.Models.IHeadMeta = {
  title: 'StartWire',
  meta: {
    description:
      'Automatic updates on your job applications from 22100+ employers! Powerful job search recommendations.',
    keywords:
      'job search, automatic status updates, distribute resume, apply for a job, startwire, find job, startwire',
  },
};

export default defaultHeadMeta;
