import React from 'react';
import { SW } from '@types';

import DefaultMarkup from './Markup';
import type { IMarkupProps } from './Markup';
import { FormControlProps } from './FormControlProps';

export const wrapWithCustomFormControl = (
  MarkupWrapper: React.ComponentType<IMarkupProps>
) => <
  TVal,
  T extends SW.Form.Input.IProps<TVal>,
  TInput extends React.ComponentType<T>
>(
  Input: TInput
) => {
  const FormControl: React.FC<FormControlProps<TVal, T>> = (props) => {
    const {
      className,
      error,
      label,
      hasEmptyLabel,
      classNames,
      ...inputProps
    } = props;

    const WrappedInput: React.ComponentType<T> = Input;

    return (
      <MarkupWrapper
        className={className}
        id={inputProps.id}
        isInvalid={inputProps.isInvalid}
        error={error}
        hasEmptyLabel={hasEmptyLabel}
        label={label}
        theme={{
          error: classNames.error,
          label: classNames.label,
        }}
      >
        <WrappedInput
          {...(inputProps as T)}
          className={props.classNames.input}
        />
      </MarkupWrapper>
    );
  };

  FormControl.defaultProps = {
    ...Input.defaultProps,
    error: null,
    hasEmptyLabel: false,
    label: null,
    classNames: {
      error: undefined,
      input: undefined,
      label: undefined,
    },
  };

  return FormControl as React.FC<FormControlProps<TVal, T>>;
};

export default wrapWithCustomFormControl(DefaultMarkup);
