import React from 'react';
import MediaQuery from 'react-responsive';

interface IDesktopMediaQueryProps {
  onMatch?(): void;
}

const DesktopMediaQuery: React.FC<IDesktopMediaQueryProps> = (props) => {
  const handleChange = (matches) => {
    if (matches) {
      props.onMatch();
    }
  };

  return (
    <MediaQuery minWidth={1136} onChange={handleChange}>
      {(matches) =>
        typeof props.children === 'function'
          ? props.children(matches)
          : matches && props.children
      }
    </MediaQuery>
  );
};

DesktopMediaQuery.defaultProps = {
  onMatch: () => {},
};

export default DesktopMediaQuery;
