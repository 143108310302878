import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';

import Button from '../../../partials/Button';
import { Form, GeneralError } from '../../../partials/form';
import { FieldWrapperProvider } from '../../../partials/form/wrappers';
import {
  EmailControl,
  SIZE_ENUM as EMAIL_SIZE,
} from '../../../partials/form/fields/Email';
import {
  TextControl,
  SIZE_ENUM as TEXT_SIZE,
} from '../../../partials/form/fields/Text';
import {
  FunctionControl,
  SIZE_ENUM as FUNCTION_SIZE,
} from '../../../partials/form/fields/Function';
import BGGray from '../../../partials/backgrounds/BGGray';
import Card from '../../../partials/Card';
import CenteredContent from '../../../partials/CenteredContent';
import withOnBoarding from '../../../../context/withOnBoarding';
import { IHttpClient, useHttpClient } from '../../../../context/withHttpClient';
import withUrlMethods from '../../../../context/withUrlMethods';
import signupAction from '../../../popups/authentication/SignUpPopup/actions';
import { useCcpaRemote, CcpaWidget } from '../../../partials/form/widgets/Ccpa';

import styles from './SignUpArea.pcss';

const HEADER = 'Apply faster. Hear back sooner.';
const SUB_HEADER =
  "Sign up for your account. Tell us where you've applied, and we'll tell you where you stand. Free forever.";

interface ISignUpAreaProps {
  className?: string;
  onSignup(
    data: object,
    httpClient: IHttpClient,
    changeLocation: any,
    startOnBoarding: any
  ): Promise<void>;
  closePopup(name: string): void;
  startOnBoarding(name: string): void;
  changeLocation(location: object): void;
}

const SignUpArea: React.FC<ISignUpAreaProps> = ({
  className,
  onSignup,
  changeLocation,
  startOnBoarding,
}) => {
  const httpClient: IHttpClient = useHttpClient();
  const handleSignUp = (formData) =>
    onSignup(formData, httpClient, changeLocation, startOnBoarding);

  const [ccpa] = useCcpaRemote();

  return (
    <Form
      id="signUpArea"
      onSubmit={handleSignUp}
      initialValues={ccpa.initialFormValues}
    >
      <FieldWrapperProvider>
        <Form.Loader>
          <Card className={className}>
            <BGGray>
              <CenteredContent>
                <div className={styles.container}>
                  <h4 className={styles.header}>{HEADER}</h4>
                  <h6 className={styles.subHeader}>{SUB_HEADER}</h6>
                  <Form.Content>
                    <div className={styles.form}>
                      <TextControl
                        className={styles.field}
                        isRequired
                        name="full_name"
                        placeholder="Your name"
                        size={TEXT_SIZE.XL}
                      />
                      <FunctionControl
                        className={cn(styles.field, styles.functionField)}
                        isRequired
                        name="function_id"
                        placeholder="What kind of job do you want"
                        size={FUNCTION_SIZE.XL}
                      />
                      <EmailControl
                        className={styles.field}
                        isRequired
                        name="email"
                        placeholder="Email Address"
                        size={EMAIL_SIZE.XL}
                      />
                      <Button
                        className={styles.button}
                        size="xl"
                        theme="orange"
                        type="submit"
                      >
                        Join Now
                      </Button>
                    </div>
                    <CcpaWidget
                      controlProps={ccpa.controlProps}
                      className={styles.ccpaWidgetContainer}
                    />
                  </Form.Content>
                  <GeneralError />
                </div>
              </CenteredContent>
            </BGGray>
          </Card>
        </Form.Loader>
      </FieldWrapperProvider>
    </Form>
  );
};

SignUpArea.defaultProps = {
  className: undefined,
};

export default withOnBoarding(
  withUrlMethods(['changeLocation'])(
    connect(null, { onSignup: signupAction })(SignUpArea)
  )
);
