import React, { PureComponent } from 'react';
import InternalForm from './InternalForm';
import FormContext from '../FormContext';
import Loader from '../../NextLoader';

interface IFormProps {
  id: string;
  initialValues?: object;
  onSubmit?(formData: object): Promise<any>;
  onSubmitFail?(errors): void;
  autoSubmit?: boolean;
  resetOnSubmit?: boolean;
  keepOnUnmount?: boolean;
}

class Form extends PureComponent<IFormProps> {
  static defaultProps = {
    initialValues: {},
    onSubmit: Promise.resolve,
    onSubmitFail: () => {},
    autoSubmit: false,
    resetOnSubmit: false,
    keepOnUnmount: false,
  };

  static Content = ({ children, className = null }) => (
    <FormContext.Consumer>
      {({ dispatchSubmit }) => (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            dispatchSubmit(e);
          }}
          className={className}
        >
          {children}
          <button type="submit" style={{ display: 'none' }} />
        </form>
      )}
    </FormContext.Consumer>
  );

  static Loader = ({ children, className = null }) => (
    <FormContext.Consumer>
      {({ isSubmitting }) => (
        <Loader className={className} indicator={isSubmitting}>
          {children}
        </Loader>
      )}
    </FormContext.Consumer>
  );

  render() {
    const {
      id,
      onSubmit,
      children,
      initialValues,
      onSubmitFail,
      autoSubmit,
      resetOnSubmit,
      keepOnUnmount,
    } = this.props;

    return (
      <InternalForm
        form={id}
        onSubmit={onSubmit}
        initialValues={initialValues}
        onSubmitFail={onSubmitFail}
        autoSubmit={autoSubmit}
        resetOnSubmit={resetOnSubmit}
        destroyOnUnmount={!keepOnUnmount}
        enableReinitialize
      >
        {children}
      </InternalForm>
    );
  }
}

export default Form;
