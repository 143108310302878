import React from 'react';
import { Field } from 'redux-form';
import type { BaseFieldProps, Validator } from 'redux-form';

import { emailValidation } from '../../validation';

interface IEmailFieldProps extends BaseFieldProps {
  validate?: Validator[];
}

const EmailField: React.FC<IEmailFieldProps> = ({ validate, ...rest }) => (
  <Field validate={[...validate, emailValidation]} {...rest} />
);

EmailField.defaultProps = {
  validate: [],
};

export default EmailField;
