import {
  FieldWrapperContext,
  wrapWithFieldWrapper,
  wrapWithFormControl,
  wrapWithReduxFormField,
  ReduxFormFieldsConfigType,
} from '../../wrappers';

import EmailField from './EmailField';
import EmailInput, { SIZE_ENUM } from '../../inputs/Email';

const config: ReduxFormFieldsConfigType<typeof EmailInput> = {
  [FieldWrapperContext.layout.DEFAULT]: wrapWithReduxFormField(
    EmailInput,
    EmailField,
    wrapWithFormControl
  ),
};

export const EmailControl = wrapWithFieldWrapper(config);

export { SIZE_ENUM };
