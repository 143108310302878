import React from 'react';

import DesktopMediaQuery from '../../media_queries/DesktopMediaQuery';
import MobileMediaQuery from '../../media_queries/MobileMediaQuery';
import BGDarkBlue from '../../backgrounds/BGDarkBlue';
import CenteredContent from '../../CenteredContent';
import SearchForm from '../../SearchForm';
import CollapsableContainer from '../../Collapsable';

interface ISearchFormWidgetProps {
  className: string;
}

const SearchFormWidget: React.FC<ISearchFormWidgetProps> = (props) => (
  <>
    <DesktopMediaQuery>
      <BGDarkBlue>
        <CenteredContent>
          <SearchForm className={props.className} prefix="layout" />
        </CenteredContent>
      </BGDarkBlue>
    </DesktopMediaQuery>
    <MobileMediaQuery>
      <CollapsableContainer id="searchFormBlock">
        <BGDarkBlue>
          <CenteredContent>
            <SearchForm className={props.className} prefix="layout" />
          </CenteredContent>
        </BGDarkBlue>
      </CollapsableContainer>
    </MobileMediaQuery>
  </>
);

SearchFormWidget.defaultProps = {
  className: null,
};

export default SearchFormWidget;
