import React from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';

import styles from './Footer.pcss';

import { COMPANY_NAME } from '../../../constants';

import CenteredContent from '../CenteredContent';
import Copyrights from './Copyrights';
import Logo from '../Logo';
import Navigation from './Navigation';
import Social from './Social';

interface IFooterProps {
  className?: string;
  isUserAuthorized: boolean;
}

const Footer: React.FC<IFooterProps> = ({ isUserAuthorized, className }) => (
  <footer className={cn(styles.container, className)}>
    <CenteredContent>
      <div className={styles.body}>
        <main className={styles.main}>
          <Logo className={styles.logo} is_authorized={isUserAuthorized} />
          <div className={styles.content}>
            {COMPANY_NAME}™ is powered by a team of recruiting industry
            veterans, job search experts, world-class technologists, and forward
            thinking investors all unified by a simple mission:{' '}
            <strong>to radically improve job search.</strong>
          </div>
          <Social className={styles.social} />
        </main>
        <Navigation className={styles.navigation} />
      </div>
      <Copyrights className={styles.copyrights} />
    </CenteredContent>
  </footer>
);

Footer.defaultProps = {
  className: null,
};

const mapStateToProps = (state) => ({
  isUserAuthorized: !!state.auth.session.current_user,
});

export default connect(mapStateToProps)(Footer);
