import React from 'react';

import CenteredContent from '../../../../partials/CenteredContent';
import TopSearchesCategory from './TopSearchesCategory';

import styles from './TopSearchesArea.pcss';

interface ITopSearchesAreaProps {
  topSearches: any;
}

const TopSearchesArea: React.FC<ITopSearchesAreaProps> = ({ topSearches }) => (
  <div className={styles.container}>
    <CenteredContent>
      <h4 className={styles.header}>
        Today
        {`'`}s Popular Searches
      </h4>
      {topSearches &&
        Object.keys(topSearches).map((name) => (
          <TopSearchesCategory
            key={name}
            name={name}
            top_searches={topSearches[name]}
          />
        ))}
    </CenteredContent>
  </div>
);

export default TopSearchesArea;
