import React from 'react';

import { Link } from '../../../../../partials/Router';
import { useUrlMethods } from '../../../../../../context/withUrlMethods';

import s from './TopSearchesCategory.pcss';

interface ITopSearchesCategoryProps {
  name: string;
  top_searches: any;
}

const TopSearchesCategory: React.FC<ITopSearchesCategoryProps> = (props) => {
  const { name, top_searches } = props;
  const { buildClientPath } = useUrlMethods();

  return (
    <div className={s.container}>
      <div className={s.header}>{name}</div>
      <ul>
        {top_searches.queries.map((query) => (
          <li key={`${top_searches.param}-${query}`}>
            <Link
              to={buildClientPath('/job/search', {
                [top_searches.param]: query,
              })}
            >
              {query}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TopSearchesCategory;
