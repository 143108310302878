import {
  FieldWrapperContext,
  wrapWithFieldWrapper,
  wrapWithFormControl,
  wrapWithReduxFormField,
  ReduxFormFieldsConfigType,
} from '../../wrappers';

import AutosuggestField from './AutosuggestField';
import AutosuggestInput, { SIZE_ENUM } from '../../inputs/Autosuggest';

const config: ReduxFormFieldsConfigType<typeof AutosuggestInput> = {
  [FieldWrapperContext.layout.DEFAULT]: wrapWithReduxFormField(
    AutosuggestInput,
    AutosuggestField,
    wrapWithFormControl
  ),
};

export const AutosuggestControl = wrapWithFieldWrapper(config);
export { SIZE_ENUM };
