import React from 'react';

import { SwitchControl } from '../../fields/Switch';
import { IControlProps } from './IControlProps';

const DEFAULT_LABEL = 'Do not sell my personal information';

interface ICcpaWidgetProps {
  controlProps: IControlProps;
  className?: string;
  label?: string;
}

const CcpaWidget: React.FC<ICcpaWidgetProps> = ({
  controlProps,
  label = DEFAULT_LABEL,
  className,
}) => {
  if (!controlProps) {
    return null;
  }
  return (
    <SwitchControl {...controlProps} title={label} className={className} />
  );
};

export default CcpaWidget;
