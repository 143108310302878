import React from 'react';
import cn from 'classnames';
import isArray from 'lodash/isArray';

import Card from '../../../../../Card';
import type { IMarkupProps } from '../types';

import styles from './Markup.pcss';

const Markup: React.FC<IMarkupProps> = (props) => {
  return (
    <div className={props.className} style={props.style}>
      <Card className={cn(styles.card, { [styles.error]: props.isInvalid })}>
        {props.children}
      </Card>
      {props.isInvalid && (
        <div className={cn(styles.errorMessage, props.theme.error)}>
          {isArray(props.error) ? props.error[0] : props.error}
        </div>
      )}
    </div>
  );
};

Markup.defaultProps = {
  className: undefined,
  style: undefined,
  error: null,
  isInvalid: false,
  label: null,
  theme: {
    error: undefined,
    label: undefined,
  },
};

export default Markup;
