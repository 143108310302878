import React from 'react';
import cn from 'classnames';
import { FaSyncAlt } from 'react-icons/fa';

import SafeContent from '../../../../SafeContent';

import styles from './Suggestion.pcss';

interface ISuggestionProps {
  highlightedValue: string;
  isHighlighted: boolean;
  suggestion: {
    support_ats: boolean;
  };
}

const Suggestion: React.FC<ISuggestionProps> = (props) => (
  <div
    className={cn(styles.suggestion, {
      [styles.suggestion_highlighted]: props.isHighlighted,
    })}
  >
    {props.suggestion.support_ats && <FaSyncAlt className={styles.icon} />}
    <SafeContent>{props.highlightedValue}</SafeContent>
  </div>
);

export default Suggestion;
