import { SW } from '@types';

// eslint-disable-next-line import/prefer-default-export
export const defaultInputProps: Partial<SW.Form.Input.IProps<any>> = {
  className: undefined,
  id: undefined,
  onBlur: () => {},
  onChange: () => {},
  onFocus: () => {},
  isInvalid: false,
  value: undefined,
};
