import React from 'react';

import { PopupLink } from '../../../partials/Router';
import { POPUP_KEY } from '../../../popups/HelpDesk/constants';

import styles from './HelpDeskButton.pcss';

const HelpDeskButton: React.FC = () => {
  return (
    <PopupLink name={POPUP_KEY} className={styles.link}>
      Support
    </PopupLink>
  );
};

export default HelpDeskButton;
