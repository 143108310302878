import {
  FieldWrapperContext,
  wrapWithFieldWrapper,
  wrapWithFormControl,
  wrapWithReduxFormField,
  ReduxFormFieldsConfigType,
} from '../../wrappers';

import TextField from './TextField';
import TextInput, { SIZE_ENUM, TYPE_ENUM } from '../../inputs/Text';

const config: ReduxFormFieldsConfigType<typeof TextInput> = {
  [FieldWrapperContext.layout.DEFAULT]: wrapWithReduxFormField(
    TextInput,
    TextField,
    wrapWithFormControl
  ),
};

export const TextControl = wrapWithFieldWrapper(config);

export { SIZE_ENUM, TYPE_ENUM };
