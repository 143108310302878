import {
  FieldWrapperContext,
  wrapWithFieldWrapper,
  wrapWithFormControl,
  wrapWithReduxFormField,
  ReduxFormFieldsConfigType,
} from '../../wrappers';

import AutosuggestCompanyTitleField from './AutosuggestCompanyTitleField';
import AutosuggestCompanyTitleInput from '../../inputs/AutosuggestCompanyTitle';

const config: ReduxFormFieldsConfigType<typeof AutosuggestCompanyTitleInput> = {
  [FieldWrapperContext.layout.DEFAULT]: wrapWithReduxFormField(
    AutosuggestCompanyTitleInput,
    AutosuggestCompanyTitleField,
    wrapWithFormControl
  ),
};

export const AutosuggestCompanyTitleControl = wrapWithFieldWrapper(config);
