import React, { useState } from 'react';
import cn from 'classnames';
import { FaSearch } from 'react-icons/fa';

import { useDispatch } from '../../../store';
import rememberLocationAction from '../../pages/Landing/actions';
import { Link } from '../Router';

import Button from '../Button';
import Dots from '../Dots';
import { useSearch } from '../../../context/WithSearch';
import { Form } from '../form';
import {
  AutosuggestControl,
  SIZE_ENUM as AS_SIZE_ENUM,
} from '../form/fields/Autosuggest';
import {
  AutosuggestLocationTitleControl,
  SIZE_ENUM as ASL_SIZE_ENUM,
} from '../form/fields/AutosuggestLocationTitle';
import { FieldWrapperProvider } from '../form/wrappers';
import {
  useUrlMethods,
  buildQueryByParams,
} from '../../../context/withUrlMethods';

import styles from './SearchForm.pcss';

const getValue = (...values) => {
  return values.find((val) => val !== undefined);
};

interface ISearchFormProps {
  className?: string;
  landing?: boolean;
  prefix?: string;
}

const SearchForm: React.FC<ISearchFormProps> = (props) => {
  const { className, landing, prefix } = props;

  const dispatch = useDispatch();
  const { getQueryParams, changeLocation } = useUrlMethods();
  const provider = useSearch();
  const search = getQueryParams();

  const [queryCache, setQueryCache] = useState<string>();
  const [locationTitleCache, setLocationTitleCache] = useState<string>();

  const queryValue = getValue(queryCache, search.query, provider.query);
  const locationValue = getValue(
    locationTitleCache,
    search.location_title,
    provider.location
  );

  const getSearchLocation = (query, location_title) => ({
    pathname: '/job/search',
    search: buildQueryByParams({
      ...search,
      ...{
        query,
        location_title,
      },
    }),
  });

  const handleSearch = ({
    query = queryValue,
    location_title = locationValue,
  }) => {
    provider.setQuery(query);
    return changeLocation(getSearchLocation(query, location_title));
  };

  const persistQuery = () => {
    provider.setQuery(queryValue);
  };

  const handleQuerySelect = (query) => {
    provider.setQuery(query);
    if (!landing) {
      handleSearch({ query });
    }
  };

  const handleLocationSelect = (location) => {
    dispatch(rememberLocationAction(location));
    provider.setLocation(location);
    if (!landing) {
      handleSearch({ location_title: location.title });
    }
  };

  return (
    <div className={className}>
      <Form
        id={`${prefix}-search-form`}
        initialValues={{
          query: queryValue,
          location_title: locationValue,
        }}
        onSubmit={handleSearch}
        keepOnUnmount
      >
        <Form.Content className={styles.form}>
          <FieldWrapperProvider>
            <AutosuggestControl
              className={styles.query}
              icon={FaSearch}
              name="query"
              onChange={setQueryCache}
              onSelect={handleQuerySelect}
              placeholder="Job title or keywords"
              query="query"
              size={AS_SIZE_ENUM.XL}
              theme={{
                input: cn({
                  [styles.input_transparent]: landing,
                  [styles.input_query]: !landing,
                }),
                suggestionsContainer: cn({
                  [styles.suggestionsContainer_transparent]: landing,
                }),
              }}
            />
            <AutosuggestLocationTitleControl
              className={cn(styles.location, {
                [styles.location_landing]: landing,
              })}
              hasIcon
              name="location_title"
              onChange={setLocationTitleCache}
              onSelect={handleLocationSelect}
              size={ASL_SIZE_ENUM.XL}
              theme={{
                input: cn({
                  [styles.input_transparent]: landing,
                  [styles.input_location]: !landing,
                }),
                suggestionsContainer: cn({
                  [styles.suggestionsContainer_transparent]: landing,
                }),
              }}
            />
            <div
              className={cn(styles.button_wrapper, {
                [styles.button_wrapper_compact]: !landing,
                [styles.button_wrapper_landing]: landing,
              })}
            >
              <Button
                className={cn(styles.button, {
                  [styles.button_compact]: !landing,
                  [styles.button_landing]: landing,
                })}
                size="xl"
                theme="orange"
                as={Link}
                to={getSearchLocation(queryValue, locationValue)}
                onClick={persistQuery}
                disabled={provider.isFetching}
              >
                {provider.isFetching ? <Dots /> : 'Find Jobs'}
              </Button>
            </div>
          </FieldWrapperProvider>
        </Form.Content>
      </Form>
    </div>
  );
};

SearchForm.defaultProps = {
  prefix: 'unknown',
  landing: false,
};

export default SearchForm;
