import {
  FieldWrapperContext,
  wrapWithFieldWrapper,
  wrapWithFormControl,
  wrapWithReduxFormField,
  ReduxFormFieldsConfigType,
} from '../../wrappers';

import SwitchField from './SwitchField';
import SwitchInput from '../../inputs/Switch';

const config: ReduxFormFieldsConfigType<typeof SwitchInput> = {
  [FieldWrapperContext.layout.DEFAULT]: wrapWithReduxFormField(
    SwitchInput,
    SwitchField,
    wrapWithFormControl
  ),
};

export const SwitchControl = wrapWithFieldWrapper(config);
