import React from 'react';
import { useAppMetadata } from '../../../context/AppMetadata';

const ClientRenderEnforcer: React.FC = (props) => {
  const appMetadata = useAppMetadata();
  return (
    <React.Fragment key={appMetadata.renderedBy}>
      {props.children}
    </React.Fragment>
  );
};

export default ClientRenderEnforcer;
