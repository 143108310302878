import React from 'react';

import styles from './InfoGraphicStat.pcss';

const statValueAndMeasure = (stat) => {
  const measurements = {
    m: 1000000,
    k: 1000,
  };

  // TODO: Eslint error should be fixed by refactoring (Denis Shvets)
  // eslint-disable-next-line no-restricted-syntax
  for (const measure in measurements) {
    if ({}.hasOwnProperty.call(measurements, measure)) {
      const denominator = measurements[measure];
      if (stat > denominator) {
        return { value: (stat / denominator).toFixed(1), measure };
      }
    }
  }
  return { value: stat };
};

interface IInfoGraphicStatProps {
  name: string;
  stat: number;
}

const InfoGraphicStat: React.FC<IInfoGraphicStatProps> = ({ stat, name }) => {
  const readableStat = statValueAndMeasure(stat);
  return (
    <div className={styles.container}>
      <h3>
        {readableStat.value}
        <div className={styles.measure}>{readableStat.measure}</div>
      </h3>
      <h6 className={styles.name}>{name}</h6>
    </div>
  );
};

export default InfoGraphicStat;
