import React from 'react';

import FieldWrapperContext from './FieldWrapperContext';

const Provider: React.FC = (props) => (
  <FieldWrapperContext.Provider value={FieldWrapperContext.layout.DEFAULT}>
    {props.children}
  </FieldWrapperContext.Provider>
);

export default Provider;
