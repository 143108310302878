import React from 'react';
import Cookies from 'js-cookie';

import Button from '../../../../partials/Button';
import { TRACKED_APPS_COOKIE_KEY } from './constants';

import { useUrlMethods } from '../../../../../context/withUrlMethods';

import { AutosuggestJobTitleControl } from '../../../../partials/form/fields/AutosuggestJobTitle';
import { AutosuggestCompanyTitleControl } from '../../../../partials/form/fields/AutosuggestCompanyTitle';
import { SIZE_ENUM as SUGGEST_SIZE } from '../../../../partials/form/inputs/Suggest';
import { Form } from '../../../../partials/form';
import { FieldWrapperProvider } from '../../../../partials/form/wrappers';

import s from './AppTrackingForm.pcss';

const getApps = (): any[] => {
  try {
    return JSON.parse(Cookies.get(TRACKED_APPS_COOKIE_KEY));
  } catch (e) {
    return [];
  }
};

const saveApp = (app) => {
  const apps = getApps();
  apps.push(app);
  Cookies.set(TRACKED_APPS_COOKIE_KEY, JSON.stringify(apps), { path: '/' });
};

export const appsCountInCookies = () => getApps().length;

const theme = { error: s.error };

const AppTrackingForm: React.FC = () => {
  const { openPopup } = useUrlMethods();

  const trackApp = (formData) => {
    openPopup('sign-up', null, null, { from: 'track-app' });
    saveApp(formData);
    return Promise.resolve();
  };

  return (
    <div className={s.container}>
      <Form id="track-app" onSubmit={trackApp} resetOnSubmit>
        <Form.Content className={s.formContainer}>
          <FieldWrapperProvider>
            <div className={s.inputContainer}>
              <AutosuggestJobTitleControl
                className={s.inputContainer}
                name="job_title"
                size={SUGGEST_SIZE.XL}
                classNames={theme}
              />
            </div>

            <div className={s.inputContainer}>
              <AutosuggestCompanyTitleControl
                className={s.inputContainer}
                name="company_title"
                size={SUGGEST_SIZE.XL}
                classNames={theme}
              />
            </div>
          </FieldWrapperProvider>

          <Button className={s.button} size="xl" theme="orange" type="submit">
            Start Tracking
          </Button>
        </Form.Content>
      </Form>
    </div>
  );
};

export default AppTrackingForm;
