export const customizedRequired = (message = 'Required field') => (value) => {
  let normalized;
  switch (true) {
    case value instanceof String:
      normalized = value.trim().length;
      break;
    case value instanceof Array:
      normalized = value.length;
      break;
    default:
      normalized = value;
  }

  return normalized ? undefined : message;
};
export const conditionalRequired = (condition) => (value) =>
  condition && !value ? 'Required field' : undefined;
// redux-form validate : (value, allValues, props, name) => error [optional]
export const required = customizedRequired();

export const emailValidation = (value) => {
  if (!value) {
    return undefined;
  }
  const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return emailRegex.test(value) ? undefined : 'Enter a valid email address';
};

export const phoneLength = (value) => {
  if (!value) {
    return undefined;
  }
  const normalized = value.replace(/[^\d]/g, '');
  return normalized.length === 10 ? undefined : 'Enter a full phone number';
};

// https://en.wikipedia.org/wiki/North_American_Numbering_Plan
export const phoneValidation = (value) => {
  if (!value) {
    return undefined;
  }
  const phoneRegex = /\([2-9]\d{2}\) [2-9](1[02-9]|[02-9]1|[02-9][02-9])-\d{4}/;
  return phoneRegex.test(value) ? undefined : 'Enter a valid phone number';
};

export const phoneMask = (value) => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, '');
  const npa = onlyNums.slice(0, 3);
  const nxx = onlyNums.slice(3, 6);
  const xxxx = onlyNums.slice(6, 10);
  if (xxxx) {
    return `(${npa}) ${nxx}-${xxxx}`;
  }
  if (nxx) {
    return `(${npa}) ${nxx}`;
  }
  return npa;
};

export const zipValid = (value) =>
  value ? undefined : 'Enter a valid zip code';

export const zipLength = (value = '') => {
  if (!value) {
    return undefined;
  }

  const normalized = value.replace(/[^\d]/g, '');
  return normalized.length === 5 ? undefined : 'Enter a full zip code';
};

export const zipMask = (value) => {
  if (!value) {
    return value;
  }

  if (value.name) {
    return value.name;
  }
  let onlyNums;
  if (value.name) {
    onlyNums = value.name.replace(/[^\d]/g, '');
  } else {
    onlyNums = value.replace(/[^\d]/g, '');
  }

  return onlyNums.slice(0, 5);
};

export const zipLengthObject = (value) => {
  if (value.name) {
    return undefined;
  }
  return value.replace(/[^\d]/g, '').length === 5
    ? undefined
    : 'Enter a full zip code';
};
export const zipMaskObject = (value) => {
  if (!value) {
    return value;
  }

  if (!value.name) {
    const onlyNums = value.replace(/[^\d]/g, '');

    return onlyNums.slice(0, 5);
  }
  return value;
};
