import React from 'react';

import MediaQuery from 'react-responsive';

interface IMobileMediaQueryProps {
  onMatch?(): void;
}

const MobileMediaQuery: React.FC<IMobileMediaQueryProps> = ({
  children,
  onMatch,
}) => {
  const handleChange = (matches) => {
    if (matches) {
      onMatch();
    }
  };

  return (
    <MediaQuery
      maxWidth={1135}
      // query="only screen and (max-width: 1135px)"
      onChange={handleChange}
    >
      {children}
    </MediaQuery>
  );
};

MobileMediaQuery.defaultProps = {
  onMatch: () => {},
};

export default MobileMediaQuery;
