import { useEffect, useState } from 'react';
import { useHttpClient } from '../../../../../context/withHttpClient';
import { useSelector, useDispatch } from '../../../../../store';
import type { ICcpa } from './ICcpa';
import type { ICcpaOptions } from './ICcpaOptions';
import buildCcpa from './buildCcpa';
import { setCcpaOptionsAction, fetchCcpaOptionsAction } from './actions';

interface IUseCccpaRemote {
  (name?: string): [ICcpa, (ccpa_options: ICcpaOptions) => void, boolean];
}

const useCcpaRemote: IUseCccpaRemote = (name = 'ccpa_do_not_sell') => {
  const httpClient = useHttpClient();
  const dispatch = useDispatch();
  const [isFetching, setFetching] = useState(false);
  const ccpaOptions = useSelector(
    (store) => store['sw-form'].widgets['ccpa-options']
  );

  const setCcpaOptions = (ccpa_options: ICcpaOptions) => {
    dispatch(setCcpaOptionsAction(ccpa_options));
  };

  useEffect(() => {
    if (!ccpaOptions && !isFetching) {
      setFetching(true);
      dispatch(fetchCcpaOptionsAction(httpClient))
        .catch(() => {})
        .finally(() => setFetching(false));
    }
  }, []);

  return [buildCcpa(ccpaOptions, name), setCcpaOptions, isFetching];
};

export default useCcpaRemote;
