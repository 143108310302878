import React from 'react';
import { connect } from 'react-redux';

import Logo from '../../Logo';

import SignButtons from './SignButtons';
import DesktopMenu from './DesktopMenu';

import s from './DesktopHeader.pcss';

interface IDesktopHeaderProps {
  customNavigation?: React.ReactNode;
  current_user: any;
  hasSignUpButton: boolean;
}

const DesktopHeader: React.FC<IDesktopHeaderProps> = ({
  customNavigation,
  current_user,
  hasSignUpButton,
}) => (
  <div className={s.wrapper}>
    <Logo is_authorized={!!current_user} />
    <div className={s.rightContainer}>
      {customNavigation ||
        (current_user ? (
          <DesktopMenu />
        ) : (
          <SignButtons hasSignUpButton={hasSignUpButton} />
        ))}
    </div>
  </div>
);

DesktopHeader.defaultProps = {
  hasSignUpButton: true,
};

const mapStateToProps = (state) => ({
  current_user: state.auth.session.current_user,
});

export default connect(mapStateToProps)(DesktopHeader);
