export const preHandleSuccessfulRegistration = async (
  changeLocation,
  askResume,
  force = false
) => {
  const location = {
    pathname: '/job/recommendations',
    search: askResume ? '?on-boarding-resume' : '?offers',
  };

  if (force) {
    await changeLocation(location);
  } else {
    await changeLocation(
      {
        state: {
          toPrivate: location,
        },
      },
      true
    );
  }
};

export const handleSuccessfulRegistration = (startOnBoarding) => {
  startOnBoarding();
};
