import React from 'react';

import { MdErrorOutline } from 'react-icons/md';

import styles from './ATSAreaFooter.pcss';

const ATSAreaFooter: React.FC = () => (
  <div className={styles.container}>
    <MdErrorOutline className={styles.icon} />
    We can provide updates on your applications from
    <b>over 10,000 employers</b>
  </div>
);

export default ATSAreaFooter;
