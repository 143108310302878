import React from 'react';

import type { IMarkupProps } from '../types';

import styles from '../Default/Markup.pcss';

type IFakeMarkupProps = Omit<IMarkupProps, 'id' | 'isInvalid'>;

const Fake: React.FC<IFakeMarkupProps> = (props) => {
  return (
    <div className={props.className} style={props.style}>
      <div className={styles.fake}>
        <div className={styles.control}>{props.children}</div>
      </div>
    </div>
  );
};

Fake.defaultProps = {
  className: undefined,
  style: undefined,
};

export default Fake;
