import React, { useRef, useEffect } from 'react';
import cn from 'classnames';
import { SW } from '@types';

import { defaultInputProps } from '../defaultInputProps';

import styles from './SwitchInput.pcss';

interface ICheckBoxInputProps extends SW.Form.Input.IProps<boolean | null> {
  isDisabled?: boolean;
  title?: string;
}

const SwitchInput: React.FC<ICheckBoxInputProps> = (props) => {
  const { id, className, value, onChange, name, isDisabled, title } = props;
  const ref = useRef<HTMLInputElement>();

  useEffect(() => {
    if (ref.current) {
      ref.current.indeterminate = value === null;
    }
  });

  return (
    <div
      className={cn(className, styles.container, {
        [styles.disabled]: isDisabled,
      })}
    >
      <label htmlFor={id || name} className={styles.input}>
        {title ? <span className={styles.label}>{title}</span> : null}
        <div className={styles.control}>
          <input
            ref={ref}
            checked={!!value}
            onChange={(e) => onChange(e.target.checked)}
            type="checkbox"
            id={id || name}
            className={styles.checkbox}
            {...(isDisabled ? { disabled: true } : {})}
          />
          <span className={styles.slider} />
        </div>
      </label>
    </div>
  );
};

SwitchInput.defaultProps = {
  ...defaultInputProps,
  isDisabled: false,
};

export default SwitchInput;
