import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import Menu from './Menu';

const MobileMenu: React.FC = () => {
  const [container, setConteiner] = useState<HTMLDivElement>();

  useEffect(() => {
    const newContainer = document.createElement('div');
    setConteiner(newContainer);
    document.getElementById('menu').appendChild(newContainer);
    return () => {
      if (document.getElementById('menu')) {
        document.getElementById('menu').removeChild(newContainer);
      }
    };
  }, []);

  if (container) {
    return ReactDOM.createPortal(<Menu />, container);
  }
  return null;
};

export default MobileMenu;
