import {
  FieldWrapperContext,
  wrapWithFieldWrapper,
  wrapWithFormControl,
  wrapWithReduxFormField,
  ReduxFormFieldsConfigType,
} from '../../wrappers';

import AutosuggestLocationTitleField from './AutosuggestLocationTitleField';
import AutosuggestLocationTitleInput, {
  SIZE_ENUM,
} from '../../inputs/AutosuggestLocationTitle';

const config: ReduxFormFieldsConfigType<typeof AutosuggestLocationTitleInput> = {
  [FieldWrapperContext.layout.DEFAULT]: wrapWithReduxFormField(
    AutosuggestLocationTitleInput,
    AutosuggestLocationTitleField,
    wrapWithFormControl
  ),
};

export const AutosuggestLocationTitleControl = wrapWithFieldWrapper(config);
export { SIZE_ENUM };
