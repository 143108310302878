import {
  FieldWrapperContext,
  wrapWithFieldWrapper,
  wrapWithFormControl,
  FormControlsConfigType,
} from '../../wrappers';

import EmailInput, { SIZE_ENUM } from './EmailInput';

const config: FormControlsConfigType<typeof EmailInput> = {
  [FieldWrapperContext.layout.DEFAULT]: wrapWithFormControl(EmailInput),
};

export const EmailControl = wrapWithFieldWrapper(config);

export default EmailInput;
export { SIZE_ENUM };
