import React from 'react';
import { connect } from 'react-redux';

import { toggle, isCollapsed } from './actions';
import { ACTION_KEY } from './constants';

interface ICollapsableToggleButtonProps {
  id: string;
  toggleComponent(data): any;
  onClickCallback?(): any;
  visibleComponents: any;
  toggle(visibleComponents, id): any;
}

class CollapsableToggleButton extends React.Component<
  ICollapsableToggleButtonProps
> {
  toggle = () => {
    if (this.props.onClickCallback) {
      this.props.onClickCallback();
    }
    this.props.toggle(this.props.visibleComponents, this.props.id);
  };

  render() {
    return (
      <div onClick={this.toggle} onKeyDown={this.toggle} role="presentation">
        {this.props.toggleComponent({
          isCollapsed: isCollapsed(this.props.visibleComponents, this.props.id),
        })}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ visibleComponents: state[ACTION_KEY] });

const mapDispatchToProps = (dispatch) => ({
  toggle: (visibleComponents, id) => dispatch(toggle(visibleComponents, id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CollapsableToggleButton);
