import React, { useMemo } from 'react';
import compact from 'lodash/compact';
import { SW } from '@types';

import { wrapWithCustomFormControl, FormControlMarkups } from '../FormControl';
import reduxFormAdapter from './reduxFormAdapter';
import { customizedRequired } from '../../validation';
import { FormControlProps } from '../FormControl/FormControlProps';
import { ReduxFieldWrapperProps } from './ReduxFieldWrapperProps';

interface IWrapper {
  <TVal, T extends SW.Form.Input.IProps<TVal>>(
    Input: React.ComponentType<T>
  ): React.ComponentType<FormControlProps<TVal, T>>;
}

const wrapWithReduxFormField = <
  TVal,
  TProps extends SW.Form.Input.IProps<TVal>,
  TInput extends React.ComponentType<TProps>
>(
  Input: TInput,
  Field,
  wrapper: IWrapper = wrapWithCustomFormControl(FormControlMarkups.Empty)
) => {
  const EnhancedInput = reduxFormAdapter(wrapper(Input));
  const ReduxFieldWrapper: React.FC<ReduxFieldWrapperProps<TVal, TProps>> = ({
    name,
    isRequired,
    requireErrorMessage,
    validate,
    onChange,
    ...rest
  }) => {
    const handleChange = (event, value) => onChange(value);
    const customRequire = useMemo(
      () => customizedRequired(requireErrorMessage),
      [requireErrorMessage]
    );

    return (
      <Field
        name={name}
        component={EnhancedInput}
        validate={compact([isRequired && customRequire, ...validate])}
        onChange={handleChange}
        {...rest}
      />
    );
  };

  ReduxFieldWrapper.defaultProps = {
    isRequired: false,
    onChange: () => {},
    validate: [],
  } as Partial<ReduxFieldWrapperProps<TVal, TProps>>;

  return ReduxFieldWrapper as React.FC<ReduxFieldWrapperProps<TVal, TProps>>;
};

export default wrapWithReduxFormField;
