import React from 'react';
import cn from 'classnames';

import CenteredContent from '../CenteredContent';
import DesktopMediaQuery from '../media_queries/DesktopMediaQuery';
import MobileMediaQuery from '../media_queries/MobileMediaQuery';
import MobileHeader from './MobileHeader';
import DesktopHeader from './DesktopHeader';
import SearchFormWidget from './SearchFormWidget';
import enforceRenderAfterHydration from '../../../common/helpers/enforceRenderAfterHydration';

import styles from './Header.pcss';

interface IHeaderProps {
  className?: string;
  customNavigation?: React.ReactNode;
  hasSearchForm?: boolean;
  hasSignUpButton?: boolean;
  isTransparent?: boolean;
}

const Header: React.FC<IHeaderProps> = ({
  className,
  customNavigation,
  hasSearchForm,
  hasSignUpButton,
  isTransparent,
}) => (
  <header
    className={cn(styles.container, className, {
      [styles.container_transparent]: isTransparent,
    })}
  >
    <CenteredContent>
      <DesktopMediaQuery>
        <DesktopHeader
          customNavigation={customNavigation}
          hasSignUpButton={hasSignUpButton}
        />
      </DesktopMediaQuery>
      <MobileMediaQuery>
        <MobileHeader
          customNavigation={customNavigation}
          noSearchForm={!hasSearchForm}
        />
      </MobileMediaQuery>
    </CenteredContent>
    {hasSearchForm && <SearchFormWidget className={styles.search} />}
  </header>
);

Header.defaultProps = {
  className: null,
  customNavigation: null,
  hasSearchForm: false,
  hasSignUpButton: true,
  isTransparent: false,
};

export default enforceRenderAfterHydration(Header);
