import React from 'react';
import { getFormValues } from 'redux-form';

import { useSelector } from '../../../../store';
import { useFormContext } from '../FormContext';

interface IDependsOnFieldsProps<TVal = any> {
  children(fieldValues: TVal): JSX.Element;
  fieldName: string;
}

const useDependsOnField = (fieldName: string) => {
  const formContext = useFormContext();
  const formValues = useSelector(getFormValues(formContext.id));
  return formValues[fieldName];
};

const DependsOnFields = <TVal,>({
  children,
  fieldName,
}: React.PropsWithChildren<IDependsOnFieldsProps<TVal>>) => {
  const fieldValue = useDependsOnField(fieldName);
  return children(fieldValue);
};

export default DependsOnFields;

export { useDependsOnField };
