import React from 'react';
import cn from 'classnames';

import { PopupLink } from '../Router';
import CenteredContent from '../CenteredContent';

import s from './AuthFooterButtons.pcss';

interface IAuthFooterButtonsProps {
  hasSignUpButton?: boolean;
}

const AuthFooterButtons: React.FC<IAuthFooterButtonsProps> = ({
  hasSignUpButton,
}) => (
  <div>
    <div className={s.fakeSpaceContainer} />
    <div className={s.container}>
      <CenteredContent>
        <div className={s.buttonsContainer}>
          {hasSignUpButton && (
            <PopupLink
              name="sign-up"
              className={s.signUpButton}
              title={`Sign Up. It's Free!`}
            />
          )}
          <PopupLink
            name="sign-in"
            className={cn(s.signInButton, {
              [s.signInButtonSolo]: !hasSignUpButton,
            })}
            title="Sign In"
          />
        </div>
      </CenteredContent>
    </div>
  </div>
);

AuthFooterButtons.defaultProps = {
  hasSignUpButton: true,
};

export default AuthFooterButtons;
