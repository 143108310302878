import React from 'react';
import pick from 'lodash/pick';
import { getFormValues } from 'redux-form';

import { useSelector } from '../../../../store';
import { useFormContext } from '../FormContext';

interface IDependsOnFieldsProps {
  children(fieldValues: Record<string, any>): JSX.Element;
  fieldNames: string[];
}

const useDependsOnFields = (fieldNames: string[]) => {
  const formContext = useFormContext();
  const formValues = useSelector(getFormValues(formContext.id));
  return pick(formValues, fieldNames);
};

const DependsOnFields: React.FC<IDependsOnFieldsProps> = ({
  children,
  fieldNames,
}) => {
  const fieldValues = useDependsOnFields(fieldNames);
  return children(fieldValues);
};

export default DependsOnFields;

export { useDependsOnFields };
