import type { ICcpa } from './ICcpa';
import type { ICcpaOptions } from './ICcpaOptions';

interface IBuildCcpa {
  (options: ICcpaOptions, controlName?: string): ICcpa;
}

const buildCcpa: IBuildCcpa = (options, controlName = 'ccpa_do_not_sell') => {
  const isVisible = options && !!options.visible;

  return {
    initialFormValues: isVisible ? { [controlName]: options.value } : {},
    changedAt: options && options.changed_at,
    value: options && options.value,
    controlProps: isVisible && {
      isDisabled: !!options.disabled,
      name: controlName,
    },
  };
};

export default buildCcpa;
