import React from 'react';

import { Link } from '../../../Router';
import HeaderPageLink from './HeaderPageLink';
import HeaderProfileLink from './HeaderProfileLink';
import { useJobRecommendationsPagePath } from '../../../../pages/JobRecommendations';
import { useUrlMethods } from '../../../../../context/withUrlMethods';

import s from './DesktopMenu.pcss';

const DesktopMenu: React.FC = () => {
  const { isPage } = useUrlMethods();
  const jobRecommendationsPagePath = useJobRecommendationsPagePath();
  return (
    <ul>
      <li className={s.item}>
        <HeaderPageLink path={'/home'}>Home</HeaderPageLink>
      </li>
      <li className={s.item}>
        <HeaderPageLink
          path={jobRecommendationsPagePath}
          altPaths={['/job/search']}
        >
          Browse Jobs
        </HeaderPageLink>
      </li>
      <li className={s.item}>
        <HeaderPageLink path={'/applications'}>Stay Organized</HeaderPageLink>
      </li>
      <li className={s.item}>
        <HeaderPageLink path={'/career-resources'}>
          Job Search Videos
        </HeaderPageLink>
      </li>
      <li className={s.item}>
        {isPage('profile') ? (
          <Link to="/logout" withouthDecoration>
            Logout
          </Link>
        ) : (
          <HeaderProfileLink />
        )}
      </li>
    </ul>
  );
};

export default DesktopMenu;
