import {
  wrapWithFieldWrapper,
  wrapWithFormControl,
  FIELD_WRAPPER_LAYOUT,
  FormControlsConfigType,
} from '../../wrappers';

import TextInput, { SIZE_ENUM, TYPE_ENUM } from './TextInput';

const config: FormControlsConfigType<typeof TextInput> = {
  [FIELD_WRAPPER_LAYOUT.DEFAULT]: wrapWithFormControl(TextInput),
};

export const TextControl = wrapWithFieldWrapper(config);

export default TextInput;
export { SIZE_ENUM, TYPE_ENUM };
