import React from 'react';
import { SW } from '@types';
import AutosuggestInput from '../Autosuggest';

type AutosuggestJobTitleInputType = SW.Utils.React.OmitProps<
  typeof AutosuggestInput,
  'query'
>;

const AutosuggestJobTitleInput: AutosuggestJobTitleInputType = (props) => (
  <AutosuggestInput
    placeholder={props.placeholder}
    query="job_title"
    {...props}
  />
);

AutosuggestJobTitleInput.defaultProps = {
  placeholder: 'Position',
};

export default AutosuggestJobTitleInput;
