import React, { useContext } from 'react';

import FieldWrapperContext, {
  FIELD_WRAPPER_LAYOUT,
} from './context/FieldWrapperContext';

type IConfig<T extends React.ComponentType> = {
  [K in FIELD_WRAPPER_LAYOUT]: T;
};

interface IWrapWithFieldWrapper {
  <TComponent extends React.ComponentType<any>>(
    config: IConfig<TComponent>
  ): TComponent;
}

const wrapWithFieldWrapper: IWrapWithFieldWrapper = <
  TComponent extends React.ComponentType<any>
>(
  config: IConfig<TComponent>
) => {
  const FieldWrapper = (props) => {
    const key = useContext<FIELD_WRAPPER_LAYOUT>(FieldWrapperContext);
    const Control = config[key];

    return <Control {...props} />;
  };

  return FieldWrapper as TComponent;
};

export default wrapWithFieldWrapper;
