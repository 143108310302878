import React from 'react';

import cn from 'classnames';
import { Link, PopupLink } from '../../../Router';

import FormContext from '../../FormContext';
import SafeContent from '../../../SafeContent';

import styles from './GeneralError.pcss';

const errorPartToJsx = (part) => {
  switch (part.type) {
    case 'text':
      return part.value;
    case 'PopupLink':
      return (
        <React.Fragment key={part.key}>
          <PopupLink {...part.props} />
        </React.Fragment>
      );
    case 'Link':
      return (
        <React.Fragment key={part.key}>
          <Link {...part.props} />
        </React.Fragment>
      );
    default:
      throw Error(`unknown part (${part.type})`);
  }
};

const getGeneralError = (error) => {
  if (typeof error === 'string') {
    return <SafeContent>{error}</SafeContent>;
  }
  if (error instanceof Array) {
    return error.map(errorPartToJsx);
  }
  throw Error(`unknown error type`);
};

interface IGeneralErrorProps {
  className?: string;
}

const GeneralError: React.FC<IGeneralErrorProps> = (props) => {
  const { className } = props;

  return (
    <FormContext.Consumer>
      {({ error }) =>
        error && (
          <div className={cn(styles.container, className)}>
            <span className={cn(styles.errorMessage)}>
              {getGeneralError(error)}
            </span>
          </div>
        )
      }
    </FormContext.Consumer>
  );
};

GeneralError.defaultProps = {
  className: null,
};

export default GeneralError;
