import React from 'react';
import { connect } from 'react-redux';
import { Link } from '../../../../Router';
import withUrlMethods from '../../../../../../context/withUrlMethods';

import styles from './HeaderProfileLink.pcss';

interface IHeaderProfileLinkProps {
  buildClientPath(path): any;
  currentUser: any;
  strength?: number;
}

const HeaderProfileLink: React.FC<IHeaderProfileLinkProps> = ({
  currentUser,
  strength,
  buildClientPath,
}) => (
  <Link
    to={buildClientPath('/profile/personal-details')}
    className={styles.container}
    withouthDecoration
  >
    <div className={styles.row1}>
      {[currentUser.first_name, currentUser.last_name].join(' ')}
    </div>
    <div className={styles.row2}>Profile Strength: {strength}%</div>
    <div className={styles.row3}>View & Edit</div>
  </Link>
);

const mapStateToProps = ({
  widgets,
  auth: {
    session: { current_user },
  },
}) => ({
  strength: widgets.profile.strength.completeness,
  currentUser: current_user,
});

export default withUrlMethods(['buildClientPath'])(
  connect(mapStateToProps)(HeaderProfileLink)
);
