import { SHOW, HIDE } from './constants';

export const hide = (id) => ({ type: HIDE, payload: id });
export const show = (id) => ({ type: SHOW, payload: id });

export const isCollapsed = (visibleComponents, id) => !visibleComponents[id];

export const toggle = (visibleComponents, id) => {
  if (isCollapsed(visibleComponents, id)) {
    return show(id);
  }
  return hide(id);
};
