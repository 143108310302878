import React from 'react';

import { useAppMetadata } from '../../../../context/AppMetadata';

const HelpDeskPortal: React.FC = () => {
  const appMetadata = useAppMetadata();

  return <div ref={appMetadata.hepldeskRef} />;
};

export default HelpDeskPortal;
