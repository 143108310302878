import React, { useContext, useEffect } from 'react';
import cn from 'classnames';
import { FaTimes } from 'react-icons/fa';

import { useSelector, useDispatch } from '../../../../../store';
import { Link, PopupLink } from '../../../Router';
import Logo from '../../../Logo';
import { LayerContext } from '../../../../layouts/Base/Layer';
import { useUrlMethods } from '../../../../../context/withUrlMethods';
import { useJobRecommendationsPagePath } from '../../../../pages/JobRecommendations';
import { COMPANY_NAME } from '../../../../../constants';

import toggleMenuAction from './actions';
import { ACTION_KEY } from './constants';

import styles from './MobileMenu.pcss';

const Menu: React.FC = () => {
  const dispatch = useDispatch();
  const isOpened = useSelector((state) => state[ACTION_KEY].isOpened);
  const strength = useSelector(
    (state) => state.widgets.profile.strength.completeness
  );
  const currentUser = useSelector((state) => state.auth.session.current_user);
  const jobRecommendationsPagePath = useJobRecommendationsPagePath();
  const { changeLocation, isPage } = useUrlMethods();
  const context = useContext(LayerContext);

  const closeMenu = () => {
    dispatch(toggleMenuAction(true, null, changeLocation, isPage, context));
  };

  const toggleMenu = (path = null) => () => {
    dispatch(toggleMenuAction(isOpened, path, changeLocation, isPage, context));
  };

  useEffect(() => {
    return closeMenu;
  }, []);

  const profilePagePath = '/profile/personal-details';

  const menu = [
    { title: 'Home', path: '/home' },
    {
      title: 'Browse Jobs',
      path: jobRecommendationsPagePath,
      altPaths: ['/job/search'],
    },
    { title: 'Stay Organized', path: '/applications' },
    { title: 'Job Search Videos', path: '/career-resources' },
    { title: `About ${COMPANY_NAME}`, path: '/about' },
  ];

  return (
    <div>
      <div
        className={cn(styles.menu, {
          [styles.open]: isOpened,
        })}
      >
        <div className={styles.header}>
          <Logo
            is_authorized={true}
            withoutText={true}
            onClick={toggleMenu()}
          />
          <FaTimes onClick={toggleMenu()} />
        </div>

        <div className={styles.userInfo}>
          <div className={styles.row1}>
            {[currentUser.first_name, currentUser.last_name].join(' ')}
          </div>
          <div className={styles.row2}>Profile Strength: {strength}%</div>
          <div
            onClick={toggleMenu(profilePagePath)}
            onKeyDown={toggleMenu(profilePagePath)}
            className={styles.row3}
            role="presentation"
          >
            View & Edit
          </div>
        </div>

        <nav>
          {menu.map(({ title, path, altPaths }) => (
            <span
              key={title}
              className={cn(styles.item, {
                [styles.active]: isPage(path) || altPaths?.some(isPage),
              })}
              onClick={toggleMenu(path)}
              onKeyDown={toggleMenu(path)}
              role="presentation"
            >
              {title}
            </span>
          ))}
          <PopupLink
            name="help-desk"
            className={styles.item}
            onClick={toggleMenu()}
          >
            <span role="presentation">Support</span>
          </PopupLink>
        </nav>

        <Link to="/logout" className={styles.button}>
          <span>Logout</span>
        </Link>
      </div>

      <div
        className={cn(styles.disabled, {
          [styles.open]: isOpened,
        })}
        onClick={toggleMenu()}
        onKeyDown={toggleMenu()}
        role="presentation"
      />
    </div>
  );
};

export default Menu;
