import wrapWithFormControl, {
  wrapWithCustomFormControl,
} from './wrapWithFormControl';

import * as FormControlMarkups from './Markup';

export { default as FormControlMarkup } from './Markup';

export default wrapWithFormControl;
export { wrapWithCustomFormControl, FormControlMarkups };

export type { IMarkupProps } from './Markup';
