import React from 'react';
import cn from 'classnames';
import { SW } from '@types';
import { defaultInputProps } from '../defaultInputProps';

import styles from './TextInput.pcss';

export enum SIZE_ENUM {
  XXL = 'xxl',
  XL = 'xl',
  L = 'l',
  M = 'm',
  S = 's',
  XS = 'xs',
}

export enum TYPE_ENUM {
  EMAIL = 'email',
  NUMBER = 'number',
  PASSWORD = 'password',
  TEL = 'tel',
  TEXT = 'text',
  HIDDEN = 'hidden',
}

interface ITextInputProps extends SW.Form.Input.IProps<string | number> {
  isDisabled?: boolean;
  placeholder?: string;
  size?: SIZE_ENUM;
  type?: TYPE_ENUM;
}

const TextInput: React.FC<ITextInputProps> = (props) => (
  <input
    className={cn(
      styles.input,
      props.className,
      styles[`input_size_${props.size}`],
      {
        [styles.input_error]: props.isInvalid,
      }
    )}
    disabled={props.isDisabled}
    id={props.id || props.name}
    name={props.name}
    onBlur={props.onBlur}
    onChange={(e) => props.onChange(e.target.value)}
    onFocus={props.onFocus}
    placeholder={props.placeholder}
    type={props.type}
    value={props.value}
  />
);

TextInput.defaultProps = {
  ...defaultInputProps,
  isDisabled: false,
  placeholder: undefined,
  size: SIZE_ENUM.M,
  type: TYPE_ENUM.TEXT,
};

export default TextInput;
