import React from 'react';

import BaseLayout from '../../layouts/Base';
import PortraitMediaQuery from '../../partials/media_queries/PortraitMediaQuery';
import Header from '../../partials/Header';
import Footer from '../../partials/Footer';
import SmartBackgroundContainer from './SmartBackgroundContainer';
import TopSearchesContainer from './TopSearchesContainer';
import AuthFooterButtons from '../../partials/AuthFooterButtons';
import HeroArea from './HeroArea';
import AppTrackingArea from './AppTrackingArea';
import AboutArea from './AboutArea';
import SignUpArea from './SignUpArea';
import PeopleStoriesArea from './PeopleStoriesArea';
import Accelerate from '../Reg/Accelerate';
import headMetaConfig from './headMetaConfig';

import s from './LandingPage.pcss';

const LandingPage: React.FC = () => (
  <BaseLayout headMetaConfig={headMetaConfig()}>
    <div className={s.app}>
      <SmartBackgroundContainer>
        <div>
          <Header isTransparent />
        </div>
        <HeroArea />
      </SmartBackgroundContainer>

      <div className={s.bgLight}>
        <AppTrackingArea />
        <Accelerate />
        <TopSearchesContainer />
        <AboutArea />
        <SignUpArea />
        <PeopleStoriesArea />
        <Footer />
      </div>
      <PortraitMediaQuery>
        <AuthFooterButtons />
      </PortraitMediaQuery>
    </div>
  </BaseLayout>
);

export default LandingPage;
